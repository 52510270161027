import React, { useState, useEffect } from "react";
import axios from "api/axios";
import VideoModal from "components/pages/video/VideoModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { HeaderTemplate, videoTemplate, titleTemplate, tagsTemplate, dateTemplate, audienceTemplate, actionTemplate } from "./Templates"; // Import templates

const Videos = () => {
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  const supervisor_status = JSON.parse(
    localStorage.getItem("supervisor_status")
  );
  const [updated, setUpdated] = useState(0);

  //datatable filters
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <HeaderTemplate  globalFilterValue={globalFilterValue} onGlobalFilterChange={onGlobalFilterChange} />
    );
  };
  // get All Added Videos
  useEffect(() => {
    (async () => {
      if (
        subscribed ||
        user_info.access === "player" ||
        user_info.access === "admin"
      ) {
        setLoading(true);
        const response = await axios.get(`api/getVideos`, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        setLoading(false);
        setVideos(response.data);
        console.log(videos);
      }
    })();
  }, [updated]);

  //delete Video
  const deleteVideo = async (id) => {
    const Video = {
      id: id,
    };
    if (window.confirm("Are you sure you want to delete this video?")) {
      try {
        const res = await axios.post("api/deleteVideo", Video, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        console.log(res);
        if (res) {
          setUpdated(Math.random());
        }
        if (res.status === 201) {
          toast.success(`SUCCESS! ${res.data.message}`, {
            theme: "colored",
          });
        }
      } catch (e) {
        if (e.response) {
          toast.error(`${e.response.data.error}`, {
            theme: "colored",
          });
        }
      }
    }
  };

 

  const filterVideos = (videos) => {
    const filteredVideos = videos.filter(
      (video) =>
        video.video.audience === user_info.access ||
        video.video.audience === "both"
    );
    return filteredVideos;
  };

  const header = renderHeader();
  return (
    <>
      {user_info.access === "admin" && <VideoModal updated={setUpdated} />}
      {subscribed ||
      (user_info.access === "player" && supervisor_status !== 0) ||
      user_info.access === "admin" ? (
        <>
          <DataTable
            paginator
            rows={5}
            value={user_info.access === "admin" ? videos : filterVideos(videos)}
            loading={loading}
            filters={filters}
            globalFilterFields={["video.name", "tags"]}
            header={header}
            rowsPerPageOptions={[5, 10, 25, 50]}
          >
            <Column header="Video" body={videoTemplate}></Column>
            <Column field="video.name" header="Title" body={titleTemplate}></Column>
            <Column field="video.tags" header="Tags" body={tagsTemplate}></Column>
            <Column
              field="date"
              header="Added On"
              body={dateTemplate}
              sortable
            ></Column>
            {user_info.access === "admin" && (
              <Column
                header="Audience"
                body={audienceTemplate}
                sortable
              ></Column>
            )}

            <Column header="Action" body={(video) => actionTemplate(video, deleteVideo, user_info)}></Column>
          </DataTable>
        </>
      ) : (
        <>Please purchase a subscription to access this content.</>
      )}
    </>
  );
};

export default Videos;
