// Templates.jsx
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const videoTemplate = (video) => {
  const found = video.video.url.includes("?");
  const video_id = found
    ? video.video.url.substring(30, video.video.url.indexOf("?"))
    : video.video.url.substring(30);
  console.log(found);
  console.log(video_id);
  return (
    <div>
      <img
        width="120"
        height="90"
        src={`http://img.youtube.com/vi/${video_id}/0.jpg`}
      />
    </div>
  );
};

export const titleTemplate = (video) => (
  <>
    <p className="text-capitalize fw-normal fs-1">{video.video.name}</p>
  </>
);

export const tagsTemplate = (video) => (
  <p className="text-muted text-capitalize">{video.tags}</p>
);

export const dateTemplate = (video) => (
  <>
    <p className="text-muted">
      {new Date(video.video.created_at).toLocaleDateString()}
    </p>
  </>
);

export const audienceTemplate = (video) => (
  <p className="text-capitalize text-muted">{video.video.audience}</p>
);

export const actionTemplate = (video, deleteVideo, user_info) => (
  <>
    {user_info.access === "admin" && (
      <Button
        variant="link"
        size="sm"
        className="mx-2"
        onClick={(e) => {
          e.preventDefault();
          deleteVideo(video.video.id);
        }}
      >
        <FontAwesomeIcon className="fs-1 text-danger" icon={"trash"} />
      </Button>
    )}
    <Link to={`/user/showvideo/${video.video.id}`}>
      <FontAwesomeIcon className="fs-1 mt-1" icon={"play"} />
    </Link>
  </>
);

export const HeaderTemplate = ({ globalFilterValue, onGlobalFilterChange }) => {
  return (
    <div className="d-flex flex-row-reverse">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search Here"
          style={{ height: "45px" }}
        />
      </span>
    </div>
  );
};


