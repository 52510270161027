import freeIcon from "assets/img/icons/free.svg";
import proIcon from "assets/img/icons/pro.svg";

export const pricingPlayer = [
  {
    id: 1,
    title: "Basic",
    // subTitle: 'For teams that need to create project plans with confidence.',
    price: "0",
    price_id: "price_1NZvYKLPp6wETtzz88gI39u",
    plan:"basic",
    url: "/user/subscription-payment/1",
    buttonText: "Subscribe",
    isFeatured: false,
    featureTitle: "CricTHROW Free",
    features: [
      { id: 1, title: "Basic Player Profile" },
      { id: 2, title: "Connect to 1 device only" },
      {
        id: 3,
        title: "Live Bowling Data",
        tag: { label: "New", type: "success" },
      },
      { id: 4, title: "Task dependencies" },
      { id: 5, title: "Firmware updates" },
    ],
  },
  {
    id: 2,
    title: "Standard",
    // subTitle:
    //   'For teams and companies that need to manage work across initiatives.',
    price: 5,
    price_id: "price_1NZvYKLPp6wETtzz88gI39wu",
    plan:"standard",
    url: "/user/subscription-payment/2",
    buttonText: "Subscribe",
    isFeatured: true,
    featureTitle: "CricTHROW CricTHROW+",
    features: [
      { id: 1, title: "Bowling History" },
      { id: 2, title: "Video Mode" },
      { id: 3, title: "Graphs and trends" },
      { id: 4, title: "training Tasks" },
      { id: 5, title: "Training videos" },
      { id: 5, title: "Instant Feedback" },
      { id: 5, title: "Session Stats" },
      { id: 5, title: "Gamiflication" },
      { id: 5, title: "Leader Board" },
      { id: 5, title: "Share Stats on Social Media" },
    ],
  }
];

export const pricingAltData = [
  {
    id: 0,
    title: "Free",
    price: 0,
    period: "m",
    icon: freeIcon,
    url: "/user/subscription-payment",
    buttonText: "Subscribe",
    isFeatured: false,
    features: [
      { id: 1, title: "Basic Player Profile", isActive: true },
      { id: 2, title: "Connect to 1 device only", isActive: true },
      { id: 3, title: "Live Bowling Data", isActive: true },
      { id: 4, title: "Task dependencies", isActive: true },
      { id: 5, title: "Firmware updates", isActive: true },
    ],
  },
  {
    id: 1,
    title: "Pro",
    price: 99,
    period: "m",
    icon: proIcon,
    url: "/user/subscription-payment",
    buttonText: "Subscribe",
    isFeatured: true,
    features: [
      { id: 1, title: "Bowling History", isActive: true  },
      { id: 2, title: "Video Mode" , isActive: true },
      { id: 3, title: "Graphs and trends", isActive: true  },
      { id: 4, title: "training Tasks", isActive: true },
      { id: 5, title: "Training videos", isActive: true  },
      { id: 5, title: "Instant Feedback" , isActive: true },
      { id: 5, title: "Session Stats" , isActive: true },
      { id: 5, title: "Gamiflication", isActive: true  },
      { id: 5, title: "Leader Board", isActive: true  },
      { id: 5, title: "Share Stats on Social Media" , isActive: true },
    ],
  },
  {
    id: 2,
    title: "Pro",
    price: 99,
    period: "m",
    icon: proIcon,
    url: "/user/subscription-payment",
    buttonText: "Subscribe",
    isFeatured: true,
    features: [
      { id: 1, title: "Multiple Player History", isActive: true  },
      {
        id: 2,
        title: "Player Comparison",
         isActive: true ,
      },
      { id: 3, title: "Connect Multiple Devices", isActive: true  },
      { id: 4, title: "Advance Analytics", isActive: true  },
      { id: 5, title: "Interface With Player/Coach", isActive: true  },
      { id: 6, title: "Metrics correlation", isActive: true  },
      { id: 7, title: "Assign Tasks", isActive: true  },
      { id: 8, title: "data Filters, sort & export", isActive: true  },
    ],
  },
];
