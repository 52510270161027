import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthSimpleLayout from "../layouts/AuthSimpleLayout";
import MainLayout from "../layouts/MainLayout";
import NoCoachLayout from "../layouts/NoCoachLayout";
import Coachdashboard from "components/dashboards/coachdashboard";
import Playerdashboard from "components/dashboards/playerdashboard";
import Admindashboard from "components/dashboards/admindashboard";
import Boarddashboard from "components/dashboards/boarddashboard";
import Developerdashboard from "components/dashboards/developerdashboard";
import Profile from "components/pages/user/profile/Profile";
import Pricing from "components/pages/pricing/pricing-default/PricingDefault";
import SubscribedPlan from "components/pages/pricing/pricing-default/SubscribedPlan";
import Subscription from "components/pages/pricing/Subscription";
import Userprofile from "components/pages/user/profile/Userprofile";
import Request from "components/pages/user/request/Request";
import Tasks from "components/pages/tasks/Tasks";
import Devices from "components/pages/devices/Devices";
import NotificationForm from "components/notification/NotificationForm";
import BowlingHistory from "components/pages/bowlinghistory/BowlingHistory";
import ConnectedPlayers from "components/pages/connectedplayers/ConnectedPlayers";
import AllUsers from "components/pages/allusers/AllUsers";
import Firmware from "components/pages/firmware/Firmware";
import Video from "components/pages/video/Video";
import ShowVideo from "components/pages/video/ShowVideo";
import Comparison from "components/pages/comparison/Comparison";
import UserDevices from "components/pages/devices/UserDevices";
import LiveBallData from "components/pages/live/LiveBallData";
import Groups from "components/pages/groups/Groups";
import BowlingOverview from "components/pages/live/BowlingOverview";
import CoachList from "components/dashboards/playerdashboard/CoachList";
import RegisterBoard from "components/dashboards/admindashboard/RegisterBoard";
import Attributes from "components/pages/allusers/Attributes";
import SimpleLogin from "components/authentication/simple/Login";
import SimpleLogout from "components/authentication/simple/Logout";
import SimpleRegistration from "components/authentication/simple/Registration";
import SimpleForgetPassword from "components/authentication/simple/ForgetPassword";
import SimplePasswordReset from "components/authentication/simple/PasswordReset";
import SimpleConfirmMail from "components/authentication/simple/ConfirmMail";
import SimpleLockScreen from "components/authentication/simple/LockScreen";

const FalconRoutes = () => {
  return (
    <Routes>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- simple ---------------------------  */}
      <Route element={<AuthSimpleLayout />}>
        <Route path="/" element={<SimpleLogin />} />
        <Route path="/register" element={<SimpleRegistration />} />
        <Route path="logout" element={<SimpleLogout />} />
        <Route path="/forgot-password" element={<SimpleForgetPassword />} />
        <Route path="/reset-password" element={<SimplePasswordReset />} />
        <Route path="/confirm-mail" element={<SimpleConfirmMail />} />
        <Route path="/lock-screen" element={<SimpleLockScreen />} />
      </Route>

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route path="/coachdashboard" element={<Coachdashboard />} />
        <Route path="/playerdashboard" element={<Playerdashboard />} />
        <Route path="/Admindashboard" element={<Admindashboard />} />
        <Route path="/boarddashboard" element={<Boarddashboard />} />
        <Route path="/developerdashboard" element={<Developerdashboard />} />
        <Route path="user/profile/:id" element={<Userprofile />} />
        <Route path="user/tasks" element={<Tasks />} />
        <Route path="user/devices" element={<UserDevices />} />
        <Route path="user/livedata" element={<LiveBallData />} />
        <Route path="user/overview" element={<BowlingOverview />} />
        <Route path="admin/devices" element={<Devices />} />
        <Route path="admin/registeruser" element={<RegisterBoard />} />
        <Route path="admin/update/attributes/:id" element={<Attributes />} />
        <Route path="user/bowlinghistory" element={<BowlingHistory />} />
        <Route path="user/bowlinghistory/:id" element={<BowlingHistory />} />
        <Route path="user/connectedplayers" element={<ConnectedPlayers />} />
        <Route path="admin/allusers" element={<AllUsers />} />
        <Route
          path="user/firmwarehistory/:device_code"
          element={<Firmware />}
        />
        <Route path="admin/addvideo" element={<Video />} />
        <Route path="admin/send/notifications/:id" element={<NotificationForm />} />
        <Route path="user/videos" element={<Video />} />
        <Route path="user/showvideo/:id" element={<ShowVideo />} />
        <Route path="user/comparison" element={<Comparison />} />
        <Route path="user/groups" element={<Groups />} />
      </Route>

      {/*No Coach Routes*/}
      <Route element={<NoCoachLayout />}>
        <Route path="user/profile" element={<Profile />} />
        <Route path="user/coachlist" element={<CoachList />} />
        <Route path="user/supervision-requests" element={<Request />} />
        <Route path="user/pricing" element={<Pricing />} />
        <Route path="user/subscriptions" element={<SubscribedPlan />} />
        <Route
          path="user/subscription-payment/:id"
          element={<Subscription />}
        />
      </Route>
    </Routes>
  );
};

export default FalconRoutes;
