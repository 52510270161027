import React, { useState, useEffect } from "react";
import axios from "api/axios";
import FirmwareModal from "components/pages/firmware/FirmwareModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
// import classNames from "classnames";

const Firmware = () => {
  const [loading, setLoading] = useState(false);
  const [firmwares, setFirmwares] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [updated, setUpdated] = useState(0);
  const { device_code } = useParams();

  //data table filtering
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex flex-row-reverse">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search Here"
            style={{ height: "45px" }}
          />
        </span>
      </div>
    );
  };

  // get All firmwares
  useEffect(() => {
    (async () => {
      setLoading(true);
      const device = {
        device_code: device_code,
      };
      const response = await axios.post(`api/displayFirmHistory`, device, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setLoading(false);
      setFirmwares(response.data);
    })();
  }, [updated]);

  //delete Device
  const deleteFirmWare = async (e) => {
    e.preventDefault();
    const FirmWare = {
      id: e.target.id,
    };

    try {
      const res = await axios.post("api/deleteFirm", FirmWare, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      if (res) {
        setUpdated(Math.random());
      }
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      if (e.response) {
        toast.error(`${e.response.data.error}`, {
          theme: "colored",
        });
      }
    }
  };

  //download firmware
  const downloadFirmware = (url) => {
    window.open(url);
  };

  const actionTemplate = (firmware) => (
    <div className="mt-1">
      <Button
        variant="link"
        size="sm"
        id={firmware.id}
        onClick={deleteFirmWare}
      >
        Delete
      </Button>

      <Button
        variant="link"
        size="sm"
        onClick={() => downloadFirmware(firmware.url)}
      >
        Download
      </Button>
    </div>
  );
  const createdAtTemplate = (firmware) => (
    <>
      {new Date(firmware.created_at).toLocaleDateString("en-US")}{" "}
      {new Date(firmware.created_at).toLocaleTimeString("en-US")}
    </>
  );

  const nameTemplate = (firmware) => (
    <>
      <p className="text-capitalize mt-2">{firmware.name}</p>
    </>
  );

  

  return (
    <>
      <h1 className="fs-1 mb-4">
        Showing Firmware history for Device {device_code}
      </h1>
      <DataTable
        paginator
        rows={5}
        value={firmwares}
        loading={loading}
        filters={filters}
        globalFilterFields={["name"]}
        header={renderHeader}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column header="Firmware Name" body={nameTemplate} sortable></Column>
        <Column header="Status" field="status" sortable></Column>
        <Column header="Created At" body={createdAtTemplate} sortable></Column>
        <Column header="Action" body={actionTemplate} ></Column>
      </DataTable>
    </>
  );
};

export default Firmware;
