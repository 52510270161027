import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar from "assets/img/team/2.jpg";
import Flex from "components/common/Flex";
import VerifiedBadge from "components/common/VerifiedBadge";
import React, { useState } from "react";
import { ListGroup, Image, Col, Row, Form, Button } from "react-bootstrap";
import ProfileBanner from "../ProfileBanner";
import PlayerModal from "./PlayerModal";
import CoachModal from "./CoachModal";
import AdminModal from "./AdminModal";
import PicureUpload from "./PictureUpload";
import { ThreeDots } from "react-loader-spinner";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";

const Banner = ({
  profileinfo,
  user_info,
  access,
  coachsubmit,
  playersubmit,
  loading,
}) => {
  const [profileSubmitted, setProfileSubmitted] = useState(false);
  return (
    <ProfileBanner>
      {loading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#F2F3F5"
          ariaLabel="three-dots-loading"
          wrapperStyle={{ margin: "0 auto" }}
          wrapperClassName="text-center"
          visible={true}
        />
      ) : (
        <>
          <ProfileBanner.Body>
            <Row className="justify-content-between">
              <Col lg={6}>
                <Image
                  width="180px"
                  height="180px"
                  rounded
                  src={
                    profileinfo.picture != "abc" ? profileinfo.picture : avatar
                  }
                  mediaClass="img-thumbnail shadow-sm "
                  className="mb-3"
                />

                <h4 className="mb-1 text-capitalize">
                  {user_info.name} <VerifiedBadge />
                </h4>

                <p className="text-500">
                  {profileSubmitted && (
                    <>
                      {profileinfo.city}, {profileinfo.country}
                    </>
                  )}
                </p>
                <div className="card flex justify-content-center"></div>

                {access === "player" && (
                  <>
                    <PlayerModal
                      profile_info={profileinfo}
                      user_info={user_info}
                      onSubmit={playersubmit}
                      userdata={user_info}
                      setProfileSubmitted={setProfileSubmitted}
                      profileSubmitted={profileSubmitted}
                    />
                  </>
                )}

                {access === "coach" && (
                  <CoachModal
                    profile_info={profileinfo}
                    onSubmit={coachsubmit}
                    userdata={user_info}
                    setProfileSubmitted={setProfileSubmitted}
                    profileSubmitted={profileSubmitted}
                  />
                )}

                {(access === "admin" || access === "board" || access === "developer") && (
                  <AdminModal
                    profile_info={profileinfo}
                    onSubmit={coachsubmit}
                    userdata={user_info}
                    setProfileSubmitted={setProfileSubmitted}
                    profileSubmitted={profileSubmitted}
                  />
                )}

                <PicureUpload />
                <div className="border-dashed border-bottom my-4 d-lg-none" />
              </Col>
              <Col className="ps-2 ps-lg-3" lg={6}>
                <Flex alignItems="center" className="mb-2">
                  <FontAwesomeIcon
                    icon="user-circle"
                    className="me-2 text-700"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">Profile Information</h6>
                  </div>
                </Flex>

                {access === "player" && (
                  <>
                    {profileSubmitted ? (
                      <>
                        <ListGroup variant="flush" className="border-left">
                          <ListGroup.Item>
                            <b>Career Level:</b> {profileinfo.careerLevel}{" "}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Club Team:</b> {profileinfo.club}{" "}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Gender:</b> {profileinfo.gender}{" "}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Bowling Arm:</b> {profileinfo.bowlingArm}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Bowling Style:</b> {profileinfo.bowlingStyle}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Height:</b> {profileinfo.height} cm
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Weight:</b> {profileinfo.weight} kg
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Category:</b> {profileinfo.playerCategory}
                          </ListGroup.Item>
                        </ListGroup>
                      </>
                    ) : (
                      <>Profile info Not added yet!.</>
                    )}
                  </>
                )}

                {access === "coach" && (
                  <>
                    {profileSubmitted ? (
                      <>
                        <ListGroup variant="flush" className="border-left">
                          <ListGroup.Item>
                            <b>Club Team:</b> {profileinfo.club}{" "}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Coaching Style:</b> {profileinfo.coachingStyle}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <b>Gender:</b> {profileinfo.gender}
                          </ListGroup.Item>
                        </ListGroup>
                      </>
                    ) : (
                      <>Profile info Not added yet!.</>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </ProfileBanner.Body>
        </>
      )}
    </ProfileBanner>
  );
};

export default Banner;
