
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useNavigate, useParams } from "react-router-dom";


// Columns for the DataTable
export const cols = [
  { field: "name", header: "Name" },
  { field: "email", header: "Email" },
  { field: "phone", header: "Phone" },
  { field: "access", header: "Role" },
  { field: "created_at", header: "Created at" },
  { field: "subcription", header: "Subcription" },
  { field: "stripe_id", header: "Stripe_id" },
];

// Array of items for the menu
export const getMenuItems = (current, blockUser, getUserProfileInfo, setVisibleRight) => [
  {
    label: "Block User",
    icon: "pi pi-fw pi-ban",
    command: () => {
      blockUser(current);
    },
  },
  {
    label: "Show Details",
    icon: "pi pi-fw pi-eye",
    command: () => {
      setVisibleRight(true);
      getUserProfileInfo(current);
    },
  },
  {
    label: "Send Notification",
    icon: "pi pi-fw pi-bell",
    command: () => {
      window.open(`/admin/send/notifications/${current}`, "_blank")
    },
  },
];

// Function to export data to PDF
export const exportPdf = (users) => {
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const doc = new jsPDF("l", 0, 0);
  doc.autoTable(exportColumns, users);
  doc.save("users_export.pdf");
};

// Function to export data to Excel
export const exportExcel = (users) => {
  const worksheet = XLSX.utils.json_to_sheet(users);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  saveAsExcelFile(excelBuffer, "users");
};

// Helper function to save file as Excel
const saveAsExcelFile = (buffer, fileName) => {
  let EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  let EXCEL_EXTENSION = ".xlsx";
  const data = new Blob([buffer], {
    type: EXCEL_TYPE,
  });

  saveAs(data, fileName + "_export" + EXCEL_EXTENSION);
};