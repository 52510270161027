import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";

function EditModal({ history, setUpdated}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const access_token = JSON.parse(localStorage.getItem("access_token"));

  const [formData, setFormData] = useState({
    time: history.time,
    flex: history.flex,
    twist: history.twist,
    force: history.force,
    runuptime: history.runuptime,
    speed: history.speed,
    step: history.step,
    datatype: history.datatype,
  });

  const [isBusy, setIsBusy] = useState(false);
  const op = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const post = {
      id: history.id,
      flex: formData.flex,
      force: formData.force,
      time: formData.time,
      step: formData.step,
      runuptime: formData.runuptime,
      datatype: formData.datatype,
    };
    let res = null;
    try {
      res = await axios.post("api/updateDeviceData", post, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      if (res) {
        if (res) {
          setUpdated(Math.random());
        }
        setShow(false)
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
      setIsBusy(false);
    } catch (e) {
      setIsBusy(false);
      toast.error(`${e.response.data.error}`, {
        theme: "colored",
      });
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Button variant="link" size="sm" className="" onClick={handleShow}>
        <FontAwesomeIcon icon={"edit"} />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">Modify Bowling Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Action Time</Form.Label>
              <Form.Control
                placeholder="action time"
                value={formData.time}
                name="time"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Flex</Form.Label>
              <Form.Control
                placeholder="flex"
                value={formData.flex}
                name="flex"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Form.Label>Arm Twist</Form.Label>
              <Form.Control
                placeholder="arm twist"
                value={formData.armTwist}
                name="armTwist"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>Force</Form.Label>
              <Form.Control
                placeholder="force"
                value={formData.force}
                name="force"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Runnup Time</Form.Label>
              <Form.Control
                placeholder="arm twist"
                value={formData.runuptime}
                name="runuptime"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Runnup Speed</Form.Label>
              <Form.Control
                placeholder="runnup speed"
                value={formData.speed}
                name="speed"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Runnup Step</Form.Label>
              <Form.Control
                placeholder="runnup step"
                value={formData.step}
                name="step"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Button className="w-100" type="submit">
                Update
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditModal;
