import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Row, Col } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";

function DeviceModal({ updated }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isBusy, setIsBusy] = useState(false);
  const [formData, setFormData] = useState({
    device_code: "",
    mac_address: "",
    player_id: user_info.id,
  });

  const [playersloading, isPlayersLoading] = useState(false);
  const [playersInfo, setPlayersInfo] = useState([]);
  // get connected players
  useEffect(() => {
    (async () => {
      isPlayersLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      console.log("console here connected players ");
      isPlayersLoading(false);
      setPlayersInfo(response.data);
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const Device = {
      player_id: formData.player_id,
      device_code: formData.device_code,
    };

    try {
      const res = await axios.post("api/userAddDevice", Device, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      updated(Math.random());
      setShow(false);
      setIsBusy(false);
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      setIsBusy(false);
      if (e.response) {
        toast.error(`ERROR! ${e.response.data.error}`, {
          theme: "colored",
        });
      }
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(formData);
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="px-3 ms-2 mb-3"
        onClick={handleShow}
      >
        <FontAwesomeIcon
          icon="plus"
          className="me-2 text-700 fs-3"
          style={{ height: "17px", width: "20px" }}
        />
        Add Device
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">Add New Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {user_info.access === "coach" && (
              <Form.Group className="mb-3">
                <Form.Label>Select player</Form.Label>
                <Form.Select
                  disabled={playersloading}
                  name="player_id"
                  value={formData.player_id}
                  onChange={handleFieldChange}
                >
                  <option>No player</option>
                  {playersInfo.map((player) => {
                    return <option value={player.user.player_id}>{player.user.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Device Code</Form.Label>
              <Form.Control
                placeholder="Enter device code"
                name="device_code"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Button
                className="w-100"
                type="submit"
                disabled={
                  !formData.device_code ||
                  isBusy
                }
              >
                Submit
              </Button>
            </Form.Group>
            {/* <Divider>or register with</Divider>
      <SocialAuthButtons /> */}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeviceModal;
