import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppContext from "../context/Context";
import classNames from "classnames";
import NavbarTop from "../components/navbar/top/NavbarTop";
import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
import Footer from "../components/footer/Footer";
import ProductProvider from "../components/app/e-commerce/ProductProvider";
import CourseProvider from "../components/app/e-learning/CourseProvider";
import { Alert } from "react-bootstrap";
import axios from "api/axios";

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes("kanban");
  // const isChat = pathname.includes('chat');
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(`api/getProfile/${user_info.id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setLoading(false);
      setProfileInfo(response.data.profile);
      console.log(profileInfo);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const user_info = JSON.parse(localStorage.getItem("user_info"));
      const access_token = JSON.parse(localStorage.getItem("access_token"));
      const player = {
        player_id: user_info.id,
      };
      if (user_info.access === "player") {
        try {
          const res = await axios.post(
            "api/getAttachedSupervisorStatus",
            player,
            {
              headers: { Authorization: `Bearer ${access_token}` },
            }
          );
          localStorage.setItem("supervisor_status", JSON.stringify(res.data));
        } catch (e) {
          if (e.response) {
            // console.log(e.response);
          }
        }
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const supervisor_status = JSON.parse(
    localStorage.getItem("supervisor_status")
  );
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!access_token) {
      navigate("/");
    }
  });

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      {(navbarPosition === "vertical" || navbarPosition === "combo") && (
        <NavbarVertical />
      )}
      {user_info != null && (
        <ProductProvider>
          <CourseProvider>
            <div className={classNames("content", { "pb-0": isKanban })}>
              <NavbarTop />
              {/*------ Main Routes ------*/}
              {user_info.access === "coach" && profileInfo != null && (
                <>
                  {(profileInfo.birthday === "NA" ||
                    profileInfo.city === "NA" ||
                    profileInfo.country === "NA" ||
                    profileInfo.gender === "NA" ||
                    profileInfo.careerLevel === "NA" ||
                    profileInfo.coachingStyle === "NA" ||
                    profileInfo.club === "NA") && (
                    <Alert>
                      Warning! Your Profile is Incomplete.{" "}
                      <Alert.Link href="/user/profile">
                        Complete Profile Now
                      </Alert.Link>
                      .
                    </Alert>
                  )}

                  {!subscribed ? (
                    <Alert>
                      Warning! You are not subscribed to any plan.{" "}
                      <Alert.Link href="/user/pricing">
                        Subscribe Now
                      </Alert.Link>
                      .
                    </Alert>
                  ) : (
                    <Outlet />
                  )}
                </>
              )}
              {user_info.access === "player" && (
                <>
                  {supervisor_status === 0 && !subscribed ? (
                    <>
                      {supervisor_status === 0 && (
                        <Alert>
                          Warning! You are not connected to a Coach.{" "}
                          <Alert.Link href="/user/coachlist">
                            Select Coach Now
                          </Alert.Link>
                          .
                        </Alert>
                      )}

                      {!subscribed && (
                        <Alert>
                          Warning! You are not subscribed to any plan.{" "}
                          <Alert.Link href="/user/pricing">
                            Subscribe Now
                          </Alert.Link>
                          .
                        </Alert>
                      )}
                    </>
                  ) : (
                    <Outlet />
                  )}
                </>
              )}
              {(user_info.access === "admin" ||
                user_info.access === "board" ||
                user_info.access === "developer") && <Outlet />}

              {!isKanban && <Footer />}
            </div>
          </CourseProvider>
        </ProductProvider>
      )}
    </div>
  );
};
export default MainLayout;
