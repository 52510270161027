import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import team3 from "../../../assets/img/team/3.jpg";
import Avatar from "../../../components/common/Avatar";
import axios from "api/axios";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  const [picture, setPicture] = useState(null);

  const logoutUser = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    (async () => {
      const response = await axios.get(`api/getProfile/${user_info.id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setPicture(response.data.profile.picture);
    })();
  }, []);
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={picture} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {localStorage.getItem("user_info") !== null && (
            <>
              {user_info.access !== "admin" && (
                <>
                  {!subscribed ? (
                    <>
                      <Dropdown.Item
                        className="fw-bold text-warning"
                        as={Link}
                        to="/user/pricing"
                      >
                        <FontAwesomeIcon icon="crown" className="me-1" />
                        <span>Go Pro</span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  ) : (
                    <>
                      <Dropdown.Item
                        as={Link}
                        className="fw-bold text-warning"
                        to="/user/subscriptions"
                      >
                        <FontAwesomeIcon icon="crown" className="me-1" />
                        <span>Subscription</span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}
                </>
              )}

              <Dropdown.Item as={Link} to="/user/profile">
                Profile &amp; account
              </Dropdown.Item>

              {user_info.access == "coach" && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to="/user/supervision-requests">
                    Supervison Requests
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Divider />
            </>
          )}

          <Dropdown.Item as={Link} onClick={logoutUser}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
