import React, { useState , useEffect} from "react";

import { Col, Row } from "react-bootstrap";
import PricingDefaultHeader from "./PricingDefaultHeader";
import PricingDefaultCard from "./PricingDefaultCard";
import useFakeFetch from "hooks/useFakeFetch";
import { pricingCoach } from "data/pricingCoach";
import { pricingPlayer } from "data/pricingPlayer";
import { faqs as faqsData } from "data/faqs";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const PricingDefault = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [faqs] = useState(faqsData);
  const navigate = useNavigate();
  const { loading: priceLoading, data: pricing } = useFakeFetch(
    user_info != null && user_info.access === "player"
      ? pricingPlayer
      : pricingCoach,
    1000
  );
  var subscribed = localStorage.getItem("subscribed");
  var plan = pricing.find(function (element) {
    return element.price_id == "price_1NZvYKLPp6wETtzz88gI39wu";
  });

  //access control
  useEffect(() => {
    if (user_info.access == "admin") {
      navigate("/admindashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  return (
    <>
      <Row className="g-0 d-flex   justify-content-center">
        <PricingDefaultHeader
          title={"Subscription Plans"}
          saving={"Save 25%"}
        />

        {priceLoading ? (
          <Col xs={12} className="py-4">
            <ThreeDots color="#F2F3F5" wrapperStyle={{ margin: "0 300px" }} />
          </Col>
        ) : (
          pricing.map((pricing) => (
            <PricingDefaultCard key={pricing.id} pricing={pricing} col={4} />
          ))
        )}
      </Row>
    </>
  );
};

export default PricingDefault;
