export const coachDashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to:'/playerdashboard',
    }
  ]
};

export const liveDataRoutes = {
  label: 'Live Tracking',
  // labelDisable: true,
  children: [
    {
      name: 'Live Tracking',
      icon: 'list',
      to: 'user/livedata',
      active: true
    },
    {
      name: 'Bowling Overview',
      icon: 'chart-pie',
      to: 'user/overview',
      active: true
    },
  ]
};

export const tasksRoutes = {
  label: 'Tasks',
  // labelDisable: true,
  children: [
    {
      name: 'Tasks',
      icon: 'list',
      to: 'user/tasks',
      active: true
    },
  ]
};
export const bowlingRoutes = {
  label: 'Bowling History',
  // labelDisable: true,
  children: [
    {
      name: 'Bowling History',
      icon: 'list',
      to: 'user/bowlinghistory',
      active: true
    },
  ]
};

// export const devicesRoutes = {
//   label: 'Devices',
//   // labelDisable: true,
//   children: [
//     {
//       name: 'Devices',
//       icon: 'mobile',
//       to: 'user/devices',
//       active: true
//     },
//   ]
// };

export const VideoRoutes = {
  label: 'Add Video',
  // labelDisable: true,
  children: [
    {
      name: 'Video Lessons',
      icon: 'book',
      to: '/user/videos',
      active: true
    },
  ]
};



export default [
  coachDashboardRoutes,
  tasksRoutes,
  bowlingRoutes,
  VideoRoutes,
];
