import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function CustomAlert({variant, heading , body}) {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant={variant} onClose={() => setShow(false)} className='alert-sm' dismissible>
        <Alert.Heading>{heading}!</Alert.Heading>
        <p>
         {body}
        </p>
      </Alert>
    );
  }
}

export default CustomAlert;