import React, { useState, useEffect } from "react";
import ProfileBanner from "./Banner";
import { Col, Row } from "react-bootstrap";
import Coachdisplay from "./Coachdisplay";
import Playersdisplay from "./Playersdisplay";
import axios from "api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [profileinfo, setProfileInfo] = useState([]);
  const [userdata, setUserData] = useState([]);
  const [profileloading, isprofileLoading] = useState(false);
  const [access, setAccess] = useState(null);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [coachloading, isCoachLoading] = useState(false);
  const [Coachinfo, setCoachInfo] = useState([]);
  const [playersloading, isPlayersLoading] = useState(false);
  const [playersInfo, setPlayersInfo] = useState([]);
  const navigate = useNavigate();
  //access control
  // useEffect(() => {
  //   if (user_info.access == "admin") {
  //     navigate("/Admindashboard");
  //     toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
  //       theme: "colored",
  //       toastId: "error1",
  //     });
  //   }
  // }, []);
  
  //get profile data
  useEffect(() => {
    (async () => {
      isprofileLoading(true);
      const response = await axios.get(`api/getProfile/${user_info.id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      isprofileLoading(false);
      setProfileUpdated(false);
      setProfileInfo(response.data.profile);
      setUserData(response.data.user);
      setAccess(response.data.user.access);
    })();
  }, [profileUpdated]);

  //get connected coach
  useEffect(() => {
    (async () => {
      isCoachLoading(true);
      const response = await axios.get(
        `api/getConnectedCoachForPlayer/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      isCoachLoading(false);
      setCoachInfo(response.data);
    })();
  }, []);

  // get connected players
  useEffect(() => {
    (async () => {
      isPlayersLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      console.log("console here connected ");
      console.log(response);
      isPlayersLoading(false);
      setPlayersInfo(response.data);
    })();
  }, []);

  //updating Player Profile Info
  const submitPlayer = async (profile) => {
    try {
      const res = await axios.post("api/updateProfilePlayer", profile, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      if (res) {
        setProfileUpdated(true);
      }
      toast.success(`SUCCESS! ${res.data.message}`, {
        theme: "colored",
      });
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  //updating Coach Profile Info
  const submitCoach = async (profile) => {
    try {
      const res = await axios.post("api/updateProfileCoach", profile, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      if (res) {
        setProfileUpdated(true);
      }
      toast.success(`SUCCESS! ${res.data.message}`, {
        theme: "colored",
      });
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

 


  return (
    <>
      <ProfileBanner
        profileinfo={profileinfo}
        user_info={userdata}
        access={access}
        coachsubmit={submitCoach}
        playersubmit={submitPlayer}
        loading={profileloading}
        updated={profileUpdated}
      />
      <Row className="g-3 mb-3">
        <Col lg={12}>
          {user_info.access === "player" && (
            <div className="sticky-sidebar">
              <Coachdisplay
                coach={Coachinfo}
                loading={coachloading}
                access={access}
              />
            </div>
          )}

          {/* {user_info.access === "coach" && (
            <div className="sticky-sidebar">
              <Playersdisplay
                players={playersInfo}
                loading={playersloading}
                access={access}
              />
            </div>
          )} */}
        </Col>
      </Row>
    </>
  );
};

export default Profile;
