import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import './helpers/initFA';


const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>
);

// Register the custom service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
    .then(function(registration) {
      console.log('Service Worker registration successful with scope: ', registration.scope);
    }).catch(function(err) {
      console.log('Service Worker registration failed: ', err);
    });
}

