import React, { useState, useEffect } from "react";
import axios from "api/axios";
import VideoModal from "components/pages/video/VideoModal";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
// import { Button } from 'primereact/button';
// import classNames from "classnames";

const Videos = () => {
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState(null);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const { id } = useParams();

  //get selected video
  useEffect(() => {
    (async () => {
      setLoading(true);
      const Video = {
        id: id,
      };
      const response = await axios.post("api/viewVideo", Video, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setLoading(false);
      console.log(response.data);
      setVideo(response.data);
    })();
  }, []);

  const VideoTemplate = (video) => (
    <div>
      {!loading && (
        <iframe
          width="750"
          height="500"
          src={video.url}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      )}
    </div>
  );

  const titleTemplate = (video) => (
    <>
      {!loading && (
        <p className="text-capitalize fw-normal fs-2">{video.name} </p>
      )}
    </>
  );

  return (
    <Container>
      <Row>
        {!loading && video != null ? (
          <Col xs={9} className="mt-2">
            <div className="card flex justify-content-center">
              <Card
                title={titleTemplate(video)}
                header={VideoTemplate(video)}
                className="md:w-25rem"
              ></Card>
            </div>
          </Col>
        ) : (
          <>
            <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#F2F3F5"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ margin: "0 350px" }}
            wrapperClassName="justif-content-center"
            visible={true}
          />
          </>
        )}
      </Row>
    </Container>
  );
};

export default Videos;
