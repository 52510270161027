import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import { generateToken, messaging } from "components/notification/firebase";

const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
    success: false,
  });
  const [isBusy, setIsBusy] = useState(false);
  const navigate = useNavigate();

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const post = {
      email: formData.email,
      password: formData.password,
    };

    try {
      const res = await axios.post("api/login", post);
      setIsBusy(false);

      if (res.status === 200) {
        localStorage.setItem(
          "access_token",
          JSON.stringify(res.data.access_token)
        );
        localStorage.setItem("user_info", JSON.stringify(res.data.access));
        let subscribed = null;
        if (res.data.access.subscriptions.length == 0) subscribed = false;
        else subscribed = true;

        localStorage.setItem("subscribed", JSON.stringify(subscribed));
        const user_info = JSON.parse(localStorage.getItem("user_info"));
        const access_token = JSON.parse(localStorage.getItem("access_token"));
        
        var device_token = await generateToken();
        if(device_token!= null){
          const info = {
            user_id: user_info.id,
            device_token_fb:device_token
          }
          const res = await axios.post("api/updateUserDeviceToken", info, {
            headers: { Authorization: `Bearer ${access_token}` },
          });
          if (res.status === 200) {
            toast.success(`Success! device token updated!`, {
              theme: "colored",
            });
          }
        }
        
        
        const player = {
          player_id: user_info.id,
        };
        if (user_info.access === "player") {
          try {
            const res = await axios.post(
              "api/getAttachedSupervisorStatus",
              player,
              {
                headers: { Authorization: `Bearer ${access_token}` },
              }
            );
            localStorage.setItem("supervisor_status", JSON.stringify(res.data));
          } catch (e) {
            if (e.response) {
              // console.log(e.response);
            }
          }
        }
        setFormData({ success: true });
        if (res.data.access.access === "player") {
          navigate("playerdashboard");
        } else if (res.data.access.access === "coach") {
          navigate("coachdashboard");
        } else if (res.data.access.access === "admin") {
          navigate("admindashboard");
        } else if (res.data.access.access === "board") {
          navigate("boarddashboard");
        } else if (res.data.access.access === "developer") {
          navigate("developerdashboard");
        }
      }
      if (res.error) {
        toast.error(`${res.data.error}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      setIsBusy(false);
      toast.error(`ERROR! ${e.response.data.error}`, {
        theme: "colored",
      });
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Password" : ""}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || isBusy}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: "simple",
  hasLabel: false,
};

export default LoginForm;
