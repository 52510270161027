import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import is from "is_js";
import AppContext from "./context/Context";
import FalconRoutes from "./routes/index";
import { CloseButton } from "./components/common/Toast";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import "primereact/resources/themes/lara-dark-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { generateToken, messaging } from "components/notification/firebase";
import { onMessage } from "firebase/messaging";
import  CustomToast  from './components/notification/CustomToast';

 

const App = () => {
  useEffect(() => {   
    onMessage(messaging, (payload) => {
      console.log(payload);
      toast.info(<CustomToast title={payload.notification.title} description={payload.notification.body} />, {
        theme: "colored",
      });
    });
  }, []);
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const {
    config: { navbarPosition },
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
    if (is.safari()) {
      HTMLClassList.add("safari");
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === "double-top") {
      HTMLClassList.add("double-top-nav-layout");
    }
    return () => HTMLClassList.remove("double-top-nav-layout");
  }, [navbarPosition]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <FalconRoutes />
      {/* <SettingsToggle />
      <SettingsPanel /> */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Router>
  );
};

export default App;
