import React from "react";
import { Card, Button, Badge } from "react-bootstrap";
import classNames from "classnames";
import Flex from "components/common/Flex";
import { ThreeDots } from "react-loader-spinner";
import CustomAlert from "components/common/CustomAlert";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import SubscriptionAlert from "components/common/SubscriptionAlert";

const Requestlog = ({
  PlayerRequest,
  CoachRequest,
  loading,
  isplayer,
  updateStatus,
}) => {
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  return (
    <Card>
      {loading ? (
        <>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#F2F3F5"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ margin: "0 auto" }}
            wrapperClassName="text-center"
            visible={true}
          />
        </>
      ) : (
        <>
          <Card.Header className="bg-light">
            <Flex justifyContent="between">
              <h5 className="mb-1 mb-md-0">Supervison request </h5>
            </Flex>
          </Card.Header>
        {subscribed ? (<>
          {isplayer && (
            <>
              <Card.Body className="p-3 ">
                {PlayerRequest.status === 0 && (
                  <div className={classNames("border-left")}>
                    <p>
                      You sent a supervison request to{" "}
                      <b>{PlayerRequest.name}</b> with email id{" "}
                      <b>{PlayerRequest.email}</b>.
                    </p>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => updateStatus(PlayerRequest.id, 3)}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {PlayerRequest.status === 1 && (
                  <div className={classNames("border-left")}>
                    <CustomAlert
                      variant={`success`}
                      heading={`Request Approved`}
                      body={`Your supervison Request is Approved
                          by ${PlayerRequest.name}`}
                    />
                  </div>
                )}
                {PlayerRequest.status === 2 && (
                  <div className={classNames("border-left")}>
                    <CustomAlert
                      variant={`danger`}
                      heading={`Request Rejected`}
                      body={`Your supervison Request is Rejected
                          by ${PlayerRequest.name}`}
                    />
                  </div>
                )}
                {PlayerRequest.status === 3 && (
                  <div className={classNames("border-left")}>
                    <p>
                      You <Badge bg="danger">Canceled Request.</Badge>
                    </p>
                    <CustomAlert
                      variant={`warning`}
                      heading={`You Canceled Request.`}
                      body={`You Canceled supervison Request`}
                    />
                  </div>
                )}
              </Card.Body>
            </>
          )}

          {!isplayer && (
            <>
            {CoachRequest.length === 0 &&
            <p className="p-3">No requests available to show!</p>
            }
              <Card.Body>
                {CoachRequest.map((Request) => (
                  <>
                    {Request.status === 0 && (
                      <>
                        <div className={classNames("border-left")}>
                          <p>
                            The player <b>{Request.name}</b> with email id{" "}
                            <b>{Request.email}</b> has submitted a supervision
                            request.
                          </p>
                          <Button
                            variant="success"
                            className="m-1"
                            size="sm"
                            onClick={() => updateStatus(Request.id, 1)}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => updateStatus(Request.id, 2)}
                          >
                            Reject
                          </Button>
                        </div>
                        <hr/>
                      </>
                    )}
                  </>
                ))}
              </Card.Body>
            </>
          )}
        </>):(
        <SubscriptionAlert/>
        )}
        
        </>
      )}
    </Card>
  );
};

export default Requestlog;
