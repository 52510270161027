import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import axios from "api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import HeaderTemplate from "./HeaderTemplate";
import ProfileSidebar from "./ProfileSidebar";
import ButtonBodyTemplate from "./ButtonBodyTemplate";
import { cols, exportExcel, exportPdf, getMenuItems } from "./Utilities"; // Import utilities

export default function AllUsers() {
  const [usersInfo, setusersInfo] = useState([]);
  const [usersLoading, isUsersLoading] = useState(false);
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [current, setCurrent] = useState(null);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const menuLeft = useRef(null);
  const [visibleRight, setVisibleRight] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const navigate = useNavigate();

  // Access control
  useEffect(() => {
    if (user_info.access == "player" || user_info.access == "coach") {
      if (user_info.access == "player") navigate("/playerdashboard");
      else if (user_info.access == "coach") navigate("/coachdashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  // Data table filtering
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <HeaderTemplate
        globalFilterValue={globalFilterValue}
        onGlobalFilterChange={onGlobalFilterChange}
        exportExcel={() => exportExcel(users)} // Use the exported function
        exportPdf={() => exportPdf(users)} // Use the exported function
      />
    );
  };

  const getUserProfileInfo = async (user_id) => {
    setProfileLoading(true);
    const response = await axios.get(`api/getProfile/${user_id}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
    setProfileInfo(response.data);
    setProfileLoading(false);
    console.log("profile api call");
  };

  const blockUser = async (user_id) => {
    const status = 0;
    const response = await axios.get(
      `/api/updateUserAccountStatus/${user_id}/${status}`,
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
    if (response.status === 201) {
      toast.success(`SUCCESS! ${response.data.message}`, {
        theme: "colored",
      });
    }
  };

  // Get connected players
  useEffect(() => {
    (async () => {
      isUsersLoading(true);
      const response = await axios.get(`api/getAllUserList`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      isUsersLoading(false);
      setusersInfo(response.data);
    })();
  }, [statusUpdated]);

  const users = usersInfo.map((userdata) => {
    return {
      name: userdata.user_name,
      email: userdata.email,
      phone: userdata.phone,
      access: userdata.access,
      created_at: userdata.created_at,
      user_name: userdata.user_name,
      subcription: userdata.sub_name,
      stripe_id: userdata.stripe_id,
    };
  });

  const nameBodyTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2 ">
        <span className="mx-2 fw-bold text-capitalize ">{data.user_name}</span>
      </div>
    );
  };

  const buttonBodyTemplate = (data) => {
    return (
      <ButtonBodyTemplate
        data={data}
        menuLeft={menuLeft}
        items={getMenuItems(current, blockUser, getUserProfileInfo, setVisibleRight)} // Use the exported function
        setCurrent={setCurrent}
      />
    );
  };

  const header = renderHeader();
  return (
    <div className="card">
      <DataTable
        value={usersInfo}
        paginator
        stripedRows
        showGridlines
        rows={5}
        dataKey="id"
        loading={usersLoading}
        emptyMessage="No record found."
        filters={filters}
        globalFilterFields={["name", "email", "country", "phone"]}
        header={header}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column header="Name" body={nameBodyTemplate} sortable />
        <Column header="Email" field="email" sortable />
        <Column header="Role" field="access" sortable />
        <Column header="Subscription" field="sub_name" sortable />
        <Column field="phone" header="Phone" sortable />
        <Column body={buttonBodyTemplate} />
      </DataTable>
      {profileInfo && visibleRight && (
        <div className="card">
          <ProfileSidebar
            visibleRight={visibleRight}
            setVisibleRight={setVisibleRight}
            profileLoading={profileLoading}
            profileInfo={profileInfo}
          />
        </div>
      )}
    </div>
  );
}