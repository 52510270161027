import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CountUp from 'react-countup';

const Statistics = ({ data, title }) => {
  return (
    <Card className="">
      <Card.Header className="pb-0">
        <h6 className="mb-2 mt-2">{title}</h6>
      </Card.Header>
      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">
          <CountUp
            start={0}
            end={data != undefined && parseFloat(data.avg).toFixed(2)}
            duration={2.75}
            // suffix={suffix}
            // prefix={prefix}
            separator=","
            decimals={2 ? 2 : 0}
            decimal="."
          />
          </h2>
          <Badge pill bg="200" className="text-danger bg-danger fs--2">
            <FontAwesomeIcon icon="caret-up"  className="me-1" />
            {data != undefined && parseFloat(data.percentage).toFixed(2)}%
          </Badge>
        </div>
        </Card.Body>
    </Card>
  );
};

Statistics.propTypes = { data: PropTypes.array.isRequired };

export default Statistics;
