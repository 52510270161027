import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "react-bootstrap";

function BallModal({ pre, post }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isBusy, setIsBusy] = useState(false);
  const op = useRef(null);
  return (
    <>
      <Button
        variant="link"
        size="sm"
        className=""
        onClick={handleShow}
        onMouseOver={(e) => op.current.toggle(e)}
        onMouseLeave={(e) => op.current.toggle(e)}
      >
        Conditions
      </Button>

      <OverlayPanel ref={op}>
        <div style={{ maxWidth: "250px", minWidth: "250px" }}>
          <p>
            <b>pre-condtions:</b> <br /> {pre ? pre : "NA"}
          </p>
          <p>
            <b>post-conditions:</b>
            <br /> {post ? post : "NA"}
          </p>
        </div>
      </OverlayPanel>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">Pre and Post Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>pre-condtions:</b> <br /> {pre ? pre : "NA"}
          </p>
          <p>
            <b>post-conditions:</b>
            <br /> {post ? post : "NA"}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BallModal;
