import Background from "components/common/Background";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CustomToast = ({ title, description }) => (
  <div className="custom-toast">
    <strong>{title}</strong>
    <div>{description}</div>
  </div>
);


export default CustomToast;
