import React, { useContext, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "../../../config";
import AppContext from "../../../context/Context";
import Flex from "../../../components/common/Flex";
import Logo from "../../../components/common/Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import coachroutes from "../../../routes/coachMaps";
import playerroutes from "../../../routes/playerMaps";
import adminroutes from "../../../routes/adminMaps";
import boardroutes from "../../../routes/boardMaps";
import developerroutes from "../../../routes/developerMaps";
import { capitalize } from "../../../helpers/utils";
import NavbarTopDropDownMenus from "../../../components/navbar/top/NavbarTopDropDownMenus";
import bgNavbar from "../../../assets/img/generic/bg-navbar.png";
import { useNavigate } from "react-router-dom";

const NavbarVertical = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const navigate = useNavigate();
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    },
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={130} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
        }}
      >
        <div className="navbar-vertical-content scrollbar">
          {localStorage.getItem("user_info") === null ? (
            <>{navigate("/")}</>
          ) : (
            <>
              {user_info.access === "coach" && (
                <>
                  <Nav className="flex-column" as="ul">
                    {coachroutes.map((route) => (
                      <Fragment key={route.label}>
                        {!route.labelDisable && (
                          <NavbarLabel label={capitalize(route.label)} />
                        )}
                        <NavbarVerticalMenu routes={route.children} />
                      </Fragment>
                    ))}
                  </Nav>
                </>
              )}

              {user_info.access === "admin" && (
                <>
                  <Nav className="flex-column" as="ul">
                    {adminroutes.map((route) => (
                      <Fragment key={route.label}>
                        {!route.labelDisable && (
                          <NavbarLabel label={capitalize(route.label)} />
                        )}
                        <NavbarVerticalMenu routes={route.children} />
                      </Fragment>
                    ))}
                  </Nav>
                </>
              )}

              {user_info.access === "player" && (
                <>
                  <Nav className="flex-column" as="ul">
                    {playerroutes.map((route) => (
                      <Fragment key={route.label}>
                        {!route.labelDisable && (
                          <NavbarLabel label={capitalize(route.label)} />
                        )}
                        <NavbarVerticalMenu routes={route.children} />
                      </Fragment>
                    ))}
                  </Nav>
                </>
              )}

              {user_info.access === "board" && (
                <>
                  <Nav className="flex-column" as="ul">
                    {boardroutes.map((route) => (
                      <Fragment key={route.label}>
                        {!route.labelDisable && (
                          <NavbarLabel label={capitalize(route.label)} />
                        )}
                        <NavbarVerticalMenu routes={route.children} />
                      </Fragment>
                    ))}
                  </Nav>
                </>
              )}

              {user_info.access === "developer" && (
                <>
                  <Nav className="flex-column" as="ul">
                    {developerroutes.map((route) => (
                      <Fragment key={route.label}>
                        {!route.labelDisable && (
                          <NavbarLabel label={capitalize(route.label)} />
                        )}
                        <NavbarVerticalMenu routes={route.children} />
                      </Fragment>
                    ))}
                  </Nav>
                </>
              )}
            </>
          )}

          {navbarPosition === "combo" && (
            <div className={`d-${topNavbarBreakpoint}-none`}>
              <div className="navbar-vertical-divider">
                <hr className="navbar-vertical-hr my-2" />
              </div>
              <Nav navbar>
                <NavbarTopDropDownMenus />
              </Nav>
            </div>
          )}

          <></>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string,
};

export default NavbarVertical;
