import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Requestlog from "./Requestlog";
import axios from "api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Request = () => {
  const [loading, isLoading] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [PlayerRequest, setPlayerRequest] = useState([]);
  const [CoachRequest, setCoachRequest] = useState([]);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const navigate = useNavigate();
  //access control
  useEffect(() => {
    if (user_info.access == "admin") {
      navigate("/admindashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);
  //get supervision request
  useEffect(() => {
    (async () => {
      isLoading(true);
      let response = null;
      if (user_info.access === "player") {
        response = await axios.get(
          `api/getSupervisionRequestsPlayer/${user_info.id}`,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );
        isLoading(false);
        console.log(response);
        setPlayerRequest(response.data);
      } else if (user_info.access === "coach") {
        response = await axios.get(
          `api/getSupervisionRequestsCoach/${user_info.id}`,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );
        isLoading(false);
        console.log(response);
        setCoachRequest(response.data);
        setStatusUpdated(false);
      }
    })();
  }, [statusUpdated]);

  // updating supervison resquest status
  const updateStatus = async (id, status) => {
    try {
      const data = {
        id: id,
        status: status,
      };
      const res = await axios.post("api/updateSupervisionRequestStatus", data, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      setStatusUpdated(true);
      toast.success(`SUCCESS! ${res.data.message}`, {
        theme: "colored",
      });
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };
  
  return (
    <>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          {user_info.access === "player" ? (
            <>
              <Requestlog
                className="mt-3"
                updateStatus={updateStatus}
                PlayerRequest={PlayerRequest}
                loading={loading}
                access={user_info.access}
                isplayer={true}
              />
            </>
          ) : (
            <>
              <Requestlog
                className="mt-3"
                updateStatus={updateStatus}
                CoachRequest={CoachRequest}
                loading={loading}
                isplayer={false}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Request;
