import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Image } from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faVoicemail } from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from "react-loader-spinner";
import classNames from "classnames";
import axios from "api/axios";
import { toast } from "react-toastify";
import AuthCornerImage from "../../../assets/img/team/3.jpg";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";

const Coachdisplay = ({ players, loading, access }) => {
  const navigate = useNavigate();
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [formData, setFormData] = useState({
    coach_id: 0,
  });
  const [coachloading, isCoachLoading] = useState(false);
  const [coachInfo, setCoachInfo] = useState([]);

  //access control
  useEffect(() => {
    if (user_info.access == "admin" || user_info.access == "coach") {
      if (user_info.access == "admin") navigate("/Admindashboard");
      else if (user_info.access == "coach") navigate("/coachdashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  // get connected players
  useEffect(() => {
    (async () => {
      isCoachLoading(true);
      const response = await axios.get("api/listCoaches");
      isCoachLoading(false);
      setCoachInfo(response.data);
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.get(
        `api/sendSuperVisionRequest/${user_info.id}/${e.target.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      console.log(res);
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      if (e.response) {
        toast.error(`ERROR! ${e.response.data.error}`, {
          theme: "colored",
        });
      }
    }
  };

  return (
    <>
      {!coachloading ? (
        <>
          {coachInfo.length != 0 ? (
            <>
              <FalconCardHeader
                title="Available Coaches"
                className="mb-3 p-2 "
              />

              <Row>
                {coachInfo.map((coach) => (
                  <Col md={6}>
                    <Card.Body className="fs--3">
                      <div className="mb-3">
                        <Card variant="dark">
                          <Card.Body>
                            <Row>
                              <Col md="4">
                                <Image
                                  fluid
                                  src={AuthCornerImage}
                                  width={140}
                                  rounded
                                />
                              </Col>
                              <Col md="8">
                                <Card.Title className="text-capitalize">
                                  {coach.name}
                                </Card.Title>
                                <Card.Text>
                                  <FontAwesomeIcon icon={faPhone} />{" "}
                                  {coach.phone}
                                  <br />
                                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                                  {coach.email}
                                </Card.Text>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  id={coach.user_id}
                                  onClick={handleSubmit}
                                >
                                  Connect
                                </Button>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                        <hr />
                      </div>
                    </Card.Body>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <>
              <p className="p-3">
                You don't have any players in your Players list!
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#F2F3F5"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ margin: "0 400px" }}
            wrapperClassName="text-center"
            visible={true}
          />
        </>
      )}
    </>
  );
};

export default Coachdisplay;
