import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Row, Col } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";

function TasksModal({ updated }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isBusy, setIsBusy] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    deadline_date: "",
    deadline_time: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const Task = {
      coach_id: user_info.id,
      title: formData.title,
      description: formData.description,
      deadline_date: formData.deadline_date,
      deadline_time: formData.deadline_time,
    };

    try {
      const res = await axios.post("api/createTask", Task, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      updated(Math.random());
      setShow(false);
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
      setIsBusy(false);
    } catch (e) {
      setIsBusy(false);
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="px-3 ms-2 mb-3"
        onClick={handleShow}
      >
        <FontAwesomeIcon
          icon="plus"
          className="me-2 text-700 fs-3"
          style={{ height: "17px", width: "20px" }}
        />
        Add Task
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">Add New Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Task Title</Form.Label>
              <Form.Control
                placeholder="Enter Task Title"
                name="title"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Task Description</Form.Label>
              <Form.Control
                placeholder="Enter Task Description"
                name="description"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Deadline Date</Form.Label>
              <Form.Control
                placeholder="Enter date"
                name="deadline_date"
                type="Date"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Deadline Time</Form.Label>
              <Form.Control
                placeholder="Enter time"
                name="deadline_time"
                type="time"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Button
                className="w-100 submit"
                type="submit"
                disabled={
                  !formData.title ||
                  !formData.description ||
                  !formData.deadline_date ||
                  !formData.deadline_time ||
                  isBusy
                }
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TasksModal;
