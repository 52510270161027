import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Row, Col } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";

function TasksModal({updated}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isBusy, setIsBusy] = useState(false);
  const [formData, setFormData] = useState({
    device_code: "",
    mac_address: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const Device = {
      // coach_id:user_info.id,
      device_code: formData.device_code,
      mac: formData.mac_address,
    };

    try {
      const res = await axios.post("api/adminAddDevice", Device, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      updated(Math.random());
      setShow(false);
      setIsBusy(false);
      if(res.status === 201){
        toast.success(`SUCCESS! ${res.data.message}`, {
        theme: "colored",
      });
      
    }
    } catch (e) {
      setIsBusy(false);
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="px-3 ms-2 mb-3"
        onClick={handleShow}
      >
        <FontAwesomeIcon
          icon="plus"
          className="me-2 text-700 fs-3"
          style={{ height: "17px", width: "20px" }}
        />
        Add Device
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">
          Add New Device 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Device Code</Form.Label>
              <Form.Control
                placeholder="Enter device code"
                name="device_code"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>MAC Address</Form.Label>
              <Form.Control
                placeholder="Enter MAC Address"
                name="mac_address"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Button
                className="w-100"
                type="submit"
                disabled={
                  !formData.device_code ||
                  !formData.mac_address ||
                  isBusy
                }
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TasksModal;
