import { React, useState, useEffect, useReducer } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ComparisonCard from "./ComparisonCard";
import { stringify } from "uuid";
function Comparison() {
  const navigate = useNavigate();
  const [playerList, setPlayerList] = useState([]);
  const [playerData, setPlayerdata] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [loading, setLoading] = useState(false);
  const [playerOne, setPlayerOne] = useState([]);
  const [playerTwo, setPlayerTwo] = useState([]);
  const [playerThree, setPlayerThree] = useState([]);
  const [reset, setReset] = useState(false);
  const [formData, setFormData] = useState({
    player_one: 0,
    player_two: 0,
    player_three: 0,
  });
  const [status, setStatus] = useState({
    player_one: 0,
    player_two: 0,
    player_three: 0,
  });
  const [colLength, setColLength] = useState(4);

  //access control
  useEffect(() => {
    if (user_info.access == "admin" || user_info.access == "player") {
      if (user_info.access == "admin") {
        navigate("/Admindashboard");
      } else {
        navigate("/playerdashboard");
      }
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  const resetComparison = () => {
    setFormData({
      player_one: 0,
      player_two: 0,
      player_three: 0,
    });
    setStatus({
      player_one: 0,
      player_two: 0,
      player_three: 0,
    });
    setReset(false);
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      setLoading(false);
      setPlayerList(response.data);
      setPlayerdata(response.data);
    })();
  };

  useEffect(() => {
    if (
      status.player_one == 0 &&
      status.player_two == 0 &&
      status.player_three == 0
    ) {
      setReset(false);
    }
  }, [status]);

  // get connected players
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      setLoading(false);
      setPlayerList(response.data);
      setPlayerdata(response.data);
    })();
  }, []);

  //add player for comparison
  async function getPlayerOneData(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setLoading(true);
    setReset(true);
    const response = await axios.get(
      `api/getPlayerOverview/${e.target.value}`,
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
    setLoading(false);
    setPlayerOne(response.data);
    const players = playerList.filter(
      (p) => p.user.player_id != e.target.value
    );
    setPlayerList(players);
    setStatus({
      ...status,
      player_one: 1,
    });
  }

  async function getPlayerTwoData(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setLoading(true);
    setReset(true);
    const response = await axios.get(
      `api/getPlayerOverview/${e.target.value}`,
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
    setLoading(false);
    setPlayerTwo(response.data);
    const players = playerList.filter(
      (p) => p.user.player_id != e.target.value
    );
    setPlayerList(players);
    setStatus({
      ...status,
      player_two: 1,
    });
  }

  async function getPlayerThreeData(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setLoading(true);
    setReset(true);
    const response = await axios.get(
      `api/getPlayerOverview/${e.target.value}`,
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
    setLoading(false);
    setPlayerThree(response.data);
    const players = playerList.filter(
      (p) => p.user.player_id != e.target.value
    );
    setPlayerList(players);
    setStatus({
      ...status,
      player_three: 1,
    });
  }

  function findPlayerInfo(player) {
    const playerInfo = playerData.filter((p) => p.user.player_id == player);
    return playerInfo[0];
  }

  function removePlayerOne(e) {
    const player = playerData.filter((p) => p.user.player_id == e.target.id);
    console.log(player);
    setPlayerOne([]);
    const temp = [...playerList, player[0]];
    setPlayerList(temp);
    setStatus({
      ...status,
      player_one: 0,
    });
    setFormData({
      ...formData,
      player_one: null,
    });
  }
  function removePlayerTwo(e) {
    const player = playerData.filter((p) => p.user.player_id == e.target.id);
    console.log(player);
    setPlayerTwo([]);
    const temp = [...playerList, player[0]];
    setPlayerList(temp);
    setStatus({
      ...status,
      player_two: 0,
    });
    setFormData({
      ...formData,
      player_two: null,
    });
  }
  function removePlayerThree(e) {
    const player = playerData.filter((p) => p.user.player_id == e.target.id);
    console.log(player);
    setPlayerThree([]);
    const temp = [...playerList, player[0]];
    setPlayerList(temp);
    setStatus({
      ...status,
      player_three: 0,
    });
    setFormData({
      ...formData,
      player_three: null,
    });
  }

  return (
    <Container>
      <Form>
        <Row>
          <Col md={colLength}>
            <Form.Group className="mb-3">
              {status.player_one == 1 ? (
                <>
                  <Form.Select
                    size="md"
                    disabled
                    name="player_one"
                    onChange={getPlayerOneData}
                  >
                    <option selected>Selected</option>
                  </Form.Select>
                </>
              ) : (
                <>
                  <Form.Select
                    size="md"
                    disabled={loading}
                    name="player_one"
                    value={formData.player_one}
                    onChange={getPlayerOneData}
                  >
                    <option>Select Player</option>

                    {playerList.map((player) => {
                      return (
                        <option value={player.user.player_id}>
                          {player.user.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </>
              )}
            </Form.Group>
          </Col>
          <Col md={colLength}>
            <Form.Group className="mb-3">
              {status.player_two == 1 ? (
                <>
                  <Form.Select
                    size="md"
                    disabled
                    name="player_two"
                    onChange={getPlayerTwoData}
                  >
                    <option selected>Selected</option>
                  </Form.Select>
                </>
              ) : (
                <>
                  <Form.Select
                    size="md"
                    disabled={loading}
                    name="player_two"
                    value={formData.player_two}
                    onChange={getPlayerTwoData}
                  >
                    <option>Select Player</option>

                    {playerList.map((player) => {
                      return (
                        <option value={player.user.player_id}>
                          {player.user.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </>
              )}
            </Form.Group>
          </Col>
          <Col md={colLength}>
            <Form.Group className="mb-3">
              {status.player_three == 1 ? (
                <>
                  <Form.Select
                    size="md"
                    disabled
                    name="player_three"
                    onChange={getPlayerThreeData}
                  >
                    <option selected>Selected</option>
                  </Form.Select>
                </>
              ) : (
                <>
                  <Form.Select
                    size="md"
                    disabled={loading}
                    name="player_three"
                    value={formData.player_three}
                    onChange={getPlayerThreeData}
                  >
                    <option>Select Player</option>

                    {playerList.map((player) => {
                      return (
                        <option value={player.user.player_id}>
                          {player.user.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Row className="border-bottom">
        <ComparisonCard
          bowlingData={playerOne}
          player={findPlayerInfo(formData.player_one)}
          removePlayer={removePlayerOne}
          status={status.player_one}
        />
        <ComparisonCard
          bowlingData={playerTwo}
          player={findPlayerInfo(formData.player_two)}
          removePlayer={removePlayerTwo}
          status={status.player_two}
        />
        <ComparisonCard
          bowlingData={playerThree}
          player={findPlayerInfo(formData.player_three)}
          removePlayer={removePlayerThree}
          status={status.player_three}
        />
      </Row>
      <Row className="justify-content-center text-center mt-4">
        <Col md={{ span: 2, offset: 10 }}>
          {reset && <Button onClick={resetComparison}>Reset</Button>}
        </Col>
      </Row>
    </Container>
  );
}

export default Comparison;
