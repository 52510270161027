import React from "react";
import { Card, Flex, Container, Row, Col } from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import Experience from "../Experience";
import { ThreeDots } from "react-loader-spinner";
import classNames from "classnames";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import SubscriptionAlert from "components/common/SubscriptionAlert";

const Coachdisplay = ({ players, loading, access }) => {
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  return (
    <Card className="mb-3">
      {!loading ? (
        <>
          {players.length != 0 ? (
            <>
              <FalconCardHeader title="Connected Players" light />
              {subscribed ? (
                <>
                  <Container>
                    <Row>
                      {players.map((player) => (
                        <Col lg={6}>
                          <Card.Body className="fs--3">
                            <div className="mb-3">
                              <Experience
                                key={player.id}
                                coach={player}
                                access={access}
                              />
                              <hr />
                            </div>
                          </Card.Body>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                </>
              ) : (
                <>
                  <SubscriptionAlert/>
                </>
              )}
            </>
          ) : (
            <>
              <p className="p-3">
                You don't have any players in your Players list!
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#F2F3F5"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ margin: "0 auto" }}
            wrapperClassName="text-center"
            visible={true}
          />
        </>
      )}
    </Card>
  );
};

export default Coachdisplay;
