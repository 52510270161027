import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button as PrimeButton } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const HeaderTemplate = ({ globalFilterValue, onGlobalFilterChange, exportExcel, exportPdf }) => {
  return (
    <div className="d-flex flex-row-reverse">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search Here"
          style={{ height: "45px" }}
        />
      </span>
      <div className="mx-3">
        <PrimeButton
          icon={
            <FontAwesomeIcon
              style={{
                fontSize: "18px",
                color: "#D3D3D3",
                marginRight: "4px",
              }}
              icon={faFileExcel}
            />
          }
          label="Excel"
          onClick={exportExcel}
          className="py-1 px-3 mt-2"
          style={{
            color: "#D3D3D3",
            background: "#04AF70",
            borderColor: "#04AF70",
          }}
          data-pr-tooltip="XLS"
        />
        <PrimeButton
          icon={
            <FontAwesomeIcon
              style={{
                fontSize: "18px",
                color: "#D3D3D3",
                marginRight: "4px",
              }}
              icon={faFilePdf}
            />
          }
          label="Pdf"
          onClick={exportPdf}
          className="py-1 px-3 mt-2 mx-2"
          style={{
            color: "#D3D3D3",
            background: "#D70040",
            borderColor: "#D70040",
          }}
          data-pr-tooltip="XLS"
        />
      </div>
    </div>
  );
};

export default HeaderTemplate;