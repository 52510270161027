import Flex from "components/common/Flex";
import VerifiedBadge from "components/common/VerifiedBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import { Button, Image } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";
import AuthCornerImage from "../../../assets/img/team/3.jpg";

const Experience = ({ coach, access }) => {
  const date = new Date(coach.created_at);
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const user_info = JSON.parse(localStorage.getItem("user_info"));

 //delete Video
  // updating supervison resquest status
  
 const disconnectFromCoach = async (id) => {
  
  if (window.confirm("Are you sure you want to disconnect from the coach?")) {
    try {
      const coach = {
        coach_id: id,
        player_id: user_info.id,
      };
      const res = await axios.post("api/removeCoachByPlayer", coach, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      // setStatusUpdated(true);
      toast.success(`SUCCESS! ${res.data.message}`, {
        theme: "colored",
      });
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  }
};
  return (
    <Flex className={classNames("align-content-center")}>
     {/* fluid for responsive image <Image fluid/> */}
      <Image  src={coach.picture} width="140px" height="140px"  rounded />
      <div className="flex-1 position-relative ps-3">
        {access === "player" ? (
          <>
            <h6 className="fs-1 mb-0 text-capitalize">
              <a href={`/user/profile/${coach.coach_id}`}>
                {" "}
                Mr. {coach.name} <VerifiedBadge />
              </a>
            </h6>{" "}
            <p className="mb-1">Proffessional Cricket Coach</p>
          </>
        ) : (
          <>
            <h6 className="fs-1 mb-0 text-capitalize">
              <a href={`/user/profile/${coach.player_id}`}>
                {" "}
               { console.log("dgta")}
               { console.log(coach)}
                Mr. {coach.name} <VerifiedBadge />
              </a>
            </h6>{" "}
            <p className="mb-1">Proffessional Cricket Player</p>
          </>
        )}

        <p className="text-1000 mb-2">
          Joined at{" "}
          {`${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`}
        </p>
        <p className="text-1000 mb-0 ">
          <FontAwesomeIcon
            icon="phone"
            className="me-2"
            style={{ height: "17px", width: "17px" }}
          />
          {coach.phone}
        </p>

        <Button onClick={() => disconnectFromCoach(coach.coach_id)} variant="link" className="text-danger"> Remove </Button>
      </div>
    </Flex>
  );
};
Experience.propTypes = {
  experience: PropTypes.shape({
    logo: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    duration: PropTypes.string,
    location: PropTypes.string,
    verified: PropTypes.bool,
  }),
  isLast: PropTypes.bool,
};
export default Experience;
