export const boardDashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to:'/boarddashboard',
    }
  ]
};


export const AllUsers = {
  label: 'All Users',
  // labelDisable: true,
  children: [
    {
      name: 'All Users',
      icon: 'users',
      to: '/admin/allusers',
      active: true
    },
  ]
};




export default [
  boardDashboardRoutes,
  AllUsers
];
