import React from "react";
import { Navigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const Dashboard = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  return (
    <>
      {user_info && (
        <>
          {user_info.access === "developer" ? (
            <>
              <>
                <Row className="g-3 mb-3">
                  <p>Welcome to Developer Dashboard</p>
                </Row>

                <Row className="g-3 mb-3"></Row>

                <Row className="g-3 mb-3"></Row>
              </>
            </>
          ) : (
            <>
              {" "}
              <Navigate to="/" />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
