import React from "react";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPlus } from "@fortawesome/free-solid-svg-icons";

const SubscriptionAlert = () => {
  return (
    <Alert variant="warning" className="m-4">
      <Alert.Heading>
        <FontAwesomeIcon icon={faLock} /> Functionality not accessible
      </Alert.Heading>
      <hr />
      <p>
        Please Subscribe to a plan to access pro features.{" "}
        <Alert.Link href="/user/pricing">Subscribe Now</Alert.Link>
      </p>
    </Alert>
  );
};

export default SubscriptionAlert;
