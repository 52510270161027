import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";
import FalconCardHeader from "components/common/FalconCardHeader";
import { useNavigate, useParams } from "react-router-dom";

const Attibutes = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isBusy, setIsBusy] = useState(false);
  const { id } = useParams();

  const [formData, setFormData] = useState({
    user_id: id,
    title: "",
    body: "",
    success: false,
  });
  const navigate = useNavigate();
  
  //access control
  useEffect(() => {
    if (user_info.access == "player" || user_info.access == "coach") {
      if (user_info.access == "player") navigate("/playerdashboard");
      else if (user_info.access == "coach") navigate("/coachdashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);


  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const post = {
      user_id: formData.user_id,
      title: formData.title,
      body: formData.body,
    };
    let res = null;
    if(id){
      try {
        res = await axios.post("api/sendFirebaseNotification", post, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        if (res.data.access_token && res.data.access_token.length !== 0) {
          setFormData({ success: true });
          toast.success(`SUCCESS! ${res.data.message}`, {
            theme: "colored",
          });
        }
        setIsBusy(false);
      } catch (e) {
        setIsBusy(false);
        if (e.response.data.error.user_id) {
          toast.error(`ERROR! ${e.response.data.error.user_id}`, {
            theme: "colored",
          });
        }
        if (e.response.data.error.title) {
          toast.error(`ERROR! ${e.response.data.error.title}`, {
            theme: "colored",
          });
        }
        if (e.response.data.error.body) {
          toast.error(`ERROR! ${e.response.data.error.body}`, {
            theme: "colored",
          });
        }
      }
    }
    
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <FalconCardHeader
              title="Send Notifications"
              className="mb-3 p-2 "
            />
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Notification Title</Form.Label>
                    <Form.Control
                      placeholder="Notification Title"
                      value={formData.title}
                      name="title"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Notification Body</Form.Label>
                    <Form.Control
                      placeholder="Notification Body"
                      as="textarea"
                      value={formData.body}
                      name="body"
                      onChange={handleFieldChange}
                      type="text"
                      rows={4}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Button
                      className="w-100"
                      type="submit"
                      disabled={!formData.title || !formData.body}
                    >
                      Send Notification Now
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Attibutes;
