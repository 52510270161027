import React, { useState, useEffect } from "react";
import ProfileBanner from "./UserBanner";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Coachdisplay from "./Coachdisplay";
import Playersdisplay from "./Playersdisplay";
import axios from "api/axios";
import { toast } from "react-toastify";

const Profile = ({ match }) => {
  const [profileinfo, setProfileInfo] = useState([]);
  const [user_info, setUserInfo] = useState([]);
  const [profileloading, isprofileLoading] = useState(false);
  const [access, setAccess] = useState(null);
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [coachloading, isCoachLoading] = useState(false);
  const [Coachinfo, setCoachInfo] = useState([]);
  const [playersloading, isPlayersLoading] = useState(false);
  const [playersInfo, setPlayersInfo] = useState([]);
  const { id } = useParams();
  //get profile data
  useEffect(() => {
    (async () => {
      let response = null;
      isprofileLoading(true);
      response = await axios.get(`api/getProfile/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      isprofileLoading(false);
      setProfileInfo(response.data.profile);
      setAccess(response.data.user.access);
      setUserInfo(response.data.user);
    })();
  }, []);

  //get connected coach
  useEffect(() => {
    (async () => {
      isCoachLoading(true);
      const response = await axios.get(`api/getConnectedCoachForPlayer/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      isCoachLoading(false);
      setCoachInfo(response.data);
    })();
  }, []);

  // get connected players
  useEffect(() => {
    (async () => {
      isPlayersLoading(true);
      const response = await axios.get(`api/getPlayerListForCoach/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log("console here connected ");
      console.log(response);
      isPlayersLoading(false);
      setPlayersInfo(response.data);
    })();
  }, []);

  return (
    <>
      <ProfileBanner
        profileinfo={profileinfo}
        user_info={user_info}
        access={access}
        loading={profileloading}
      />
      <Row className="g-3 mb-3">
        <Col lg={12}>
         

          
        </Col>
      </Row>
    </>
  );
};

export default Profile;
