import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Avatar } from 'primereact/avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMale, faMedal, faRunning, faBowlingBall, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { ThreeDots } from 'react-loader-spinner';
import { Button as NewButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProfileSidebar = ({ visibleRight, setVisibleRight, profileLoading, profileInfo }) => {
  return (
    <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
      {profileLoading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#F2F3F5"
          ariaLabel="three-dots-loading"
          wrapperStyle={{ margin: "0 80px" }}
          wrapperClassName="text-center"
          visible={true}
        />
      ) : (
        <>
          <div className="text-center">
            <Avatar
              image={profileInfo.profile.picture}
              size="xlarge"
              shape="circle"
              className="mb-2"
              style={{ width: "100px", height: "100px" }}
            />
            <p className=" text-capitalize fw-bold mb-3">
              {profileInfo.user.name}
            </p>
          </div>
          <ul class="list-group border-0 ">
            <li class="list-group-item border-0 border-bottom border-top text-capitalize p-3">
              <FontAwesomeIcon icon={faEnvelope} className="mx-2" />{" "}
              {profileInfo.user.email}
            </li>
            <li class="list-group-item  border-0 border-bottom text-capitalize  p-3">
              <FontAwesomeIcon icon={faPhone} className="mx-2" />{" "}
              {profileInfo.user.phone}
            </li>
            <li class="list-group-item  border-0 border-bottom text-capitalize p-3">
              <FontAwesomeIcon icon={faMale} className="mx-2 fs-2" />{" "}
              {profileInfo.profile.gender}
            </li>
            <li class="list-group-item  border-0 border-bottom text-capitalize p-3">
              <FontAwesomeIcon icon={faMedal} className="mx-2" />{" "}
              {profileInfo.profile.careerLevel} Career Level
            </li>
            <li class="list-group-item  border-0 border-bottom text-capitalize p-3">
              <FontAwesomeIcon icon={faRunning} className="mx-2" />{" "}
              {profileInfo.profile.bowlingArm} Bowling Arm
            </li>
            <li class="list-group-item  border-0 border-bottom text-capitalize p-3">
              <FontAwesomeIcon icon={faBowlingBall} className="mx-2" />{" "}
              {profileInfo.profile.bowlingStyle} Bowling Style
            </li>
            <li class="list-group-item  border-0 border-bottom p-3">
              <FontAwesomeIcon icon={faUserFriends} className="mx-2" />{" "}
              {profileInfo.profile.playerCategory} Player Category
            </li>
          </ul>
          <div className="text-center mt-3">
            <NewButton variant="primary">
              <Link
                to={`/user/bowlinghistory/${profileInfo.user.id}`}
                className="ml-3"
                target="_blank"
                style={{ color: "white", fontSize: "14px" }}
              >
                Bowling Data
              </Link>
            </NewButton>

            <NewButton variant="secondary" className="mx-2">
              <Link
                to={`/admin/update/attributes/${profileInfo.user.id}`}
                className="ml-3"
                target="_blank"
                style={{ color: "white", fontSize: "14px" }}
              >
                Attributes
              </Link>
            </NewButton>
          </div>
        </>
      )}
    </Sidebar>
  );
};

export default ProfileSidebar;