import React, { useState, useEffect } from "react";
import axios from "api/axios";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import SubscriptionAlert from "components/common/SubscriptionAlert";

// import classNames from "classnames";

const BowlingOverview = () => {
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  const [connectionStatus, setConnectionStatus] = useState("Not Connected");
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));


  const [dataLoading, isDataLoading] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  // get connected players
  useEffect(() => {
    (async () => {
      isDataLoading(true);
      const response = await axios.get(
        `api/getPlayerOverview/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      console.log("player data ");
      console.log(response);
      isDataLoading(false);
      setDisplayData(response.data);
    })();
  }, []);

  return (
    <>
      {subscribed ? (
        <>
          <Container>
            <Row className="text-center">
              <Col md="4" style={{ "margin-top": "55px" }}></Col>
              <Col md="4">
                <h3>Live Ball Data</h3>
                <h1 className=" fs-6">0</h1>
                <h3>Legal</h3>
              </Col>
              <Col md="4" className="mt-2"></Col>
            </Row>
            <Row className="mt-3 text-center">
              <Col md={4} className="mb-4">
                <Card className="p-2">
                  <Card.Body>
                    <Card.Title className="fs-3">
                      {" "}
                      {displayData.flex != null ? (
                        <>{parseFloat(displayData.flex).toFixed(2)}</>
                      ) : (
                        <>0</>
                      )}
                    </Card.Title>
                    <p>Flex</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="p-2">
                  <Card.Body>
                    <Card.Title className="fs-3">
                      {" "}
                      {displayData.force != null ? (
                        <>{parseFloat(displayData.force).toFixed(2)}</>
                      ) : (
                        <>0</>
                      )}{" "}
                    </Card.Title>
                    <p>Force</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="p-2">
                  <Card.Body>
                    <Card.Title className="fs-3">
                      {" "}
                      {displayData.time != null ? (
                        <>{parseFloat(displayData.time).toFixed(2)}</>
                      ) : (
                        <>0</>
                      )}{" "}
                    </Card.Title>
                    <p>Time</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="p-2">
                  <Card.Body>
                    <Card.Title className="fs-3">
                      {" "}
                      {displayData.steps != null ? (
                        <>{parseFloat(displayData.steps).toFixed(2)}</>
                      ) : (
                        <>0</>
                      )}{" "}
                    </Card.Title>
                    <p>Steps</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="p-2">
                  <Card.Body>
                    <Card.Title className="fs-3">
                      {displayData.runuptime != null ? (
                        <>{parseFloat(displayData.runuptime).toFixed(2)}</>
                      ) : (
                        <>0</>
                      )}
                    </Card.Title>
                    <p>RunTime</p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="2" className="mt-3 text-center"></Col>
              <Col md="4" className="mt-3 text-center">
                <h1 className=" fs-6">0</h1>
                <p className=" fs-3">TOTAL BALLS</p>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="1">
                    <h1 className=" fs-6 text-success">0</h1>
                  </Col>
                  <Col md="9">
                    <p className="mt-2 fs-3">Legal</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <h1 className=" fs-6 text-danger">0</h1>
                  </Col>
                  <Col md="9">
                    <p className="mt-2 fs-3">Illegal</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          {" "}
       <SubscriptionAlert/>
        </>
      )}
    </>
  );
};

export default BowlingOverview;
