import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import axios from "api/axios";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row, Button as ReactButton } from "react-bootstrap";

import {
  faBowlingBall,
  faEnvelope,
  faMale,
  faMedal,
  faPhone,
  faRunning,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ConnectedPlayers() {
  const [playersInfo, setPlayersInfo] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState([]);
  const [playersloading, isPlayersLoading] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [current, setCurrent] = useState(null);
  const [supervision, setSuperVision] = useState(null);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const menuLeft = useRef(null);
  const [visibleRight, setVisibleRight] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    gender: "NA",
    bowlingArm: "NA",
    bowlingStyle: "NA",
  });

  //access control
  useEffect(() => {
    if (user_info.access == "admin" || user_info.access == "player") {
      if (user_info.access == "admin") {
        navigate("/Admindashboard");
      } else {
        navigate("/playerdashboard");
      }
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  

  //data table filtering
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <Row>
        <Col md={2} className="my-2">
          <Form.Select
            value={formData.bowlingArm}
            onChange={handleFieldChange}
            name="bowlingArm"
            size="sm"
            disabled={loading}
            className="me-3"
          >
            <option value="NA">Bowling Arm</option>
            <option value="left">Left</option>
            <option value="right">Right</option>
          </Form.Select>
        </Col>

        <Col md={2} className="my-2">
          <Form.Select
            value={formData.bowlingStyle}
            onChange={handleFieldChange}
            name="bowlingStyle"
            size="sm"
            disabled={loading}
            className="me-3"
          >
            <option value="NA">Bowling Style</option>
            <option value="fast">Fast</option>
            <option value="medium fast">Medium Fast</option>
            <option value="leg break">Leg Break</option>
            <option value="off break">Off Break</option>
            <option value="slow orthodox">Slow Orthodox</option>
            <option value="slow chinaman">Slow Chinaman</option>
          </Form.Select>
        </Col>
        <Col md={2} className="my-2">
          <Form.Select
            value={formData.gender}
            onChange={handleFieldChange}
            name="gender"
            size="sm"
            disabled={loading}
            className="me-3"
          >
            <option value="NA">Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Form.Select>
        </Col>
        <Col md={3} className="my-2">
          <ReactButton
            type="submit"
            size="sm"
            id="btn"
            className="btn btn-primary  text-light"
            onClick={handleSubmit}
          >
            Apply{" "}
          </ReactButton>
        </Col>

        <Col md={3} className="mb-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search Here"
              style={{ height: "45px" }}
            />
          </span>
        </Col>
      </Row>
    );
  };

  // updating supervison resquest status
  const updateStatus = async (id, status) => {
    try {
      const data = {
        id: id,
        status: status,
      };
      const res = await axios.post("api/updateSupervisionRequestStatus", data, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      setStatusUpdated(true);
      toast.success(`SUCCESS! ${res.data.message}`, {
        theme: "colored",
      });
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  function checkProfile(data) {
    return data.user.player_id == current;
  }

  const items = [
    {
      label: "Remove Player",
      icon: "pi pi-fw pi-trash",
      command: () => {
        updateStatus(supervision, 4);
      },
    },
    {
      label: "Show Details",
      icon: "pi pi-fw pi-eye",
      command: () => {
        setCurrentPlayer(playersInfo.filter(checkProfile));
        setVisibleRight(true);
      },
    },
    {
      label: "Bowling History",
      icon: "pi pi-fw pi-history",
      command: () => {
        window.open(`/user/bowlinghistory/${current}`, "_blank");
      },
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    isPlayersLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}/${formData.bowlingArm}/${formData.bowlingStyle}/${formData.gender}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      isPlayersLoading(false);
      setPlayersInfo(response.data);
  };

  // get connected players
  useEffect(() => {
    (async () => {
      isPlayersLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      isPlayersLoading(false);
      setPlayersInfo(response.data);
    })();
  }, [statusUpdated]);

  //data templates starts here
  const nameBodyTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2 ">
        <img className="rounded-circle" src={data.user.picture} width="32" />
        <span className="mx-2 fw-bold text-capitalize ">
          <Link to={`/user/profile/${data.user.user_id}`}>
            {data.user.name}
          </Link>
        </span>
      </div>
    );
  };

 

  const flexBodyTemplate = (data) => {
    return <div>{parseFloat(data.flex).toFixed(2)}</div>;
  };

  const forceBodyTemplate = (data) => {
    return <div>{parseFloat(data.force).toFixed(2)}</div>;
  };

  const runuptimeBodyTemplate = (data) => {
    return <div>{parseFloat(data.runuptime).toFixed(2)}</div>;
  };

  const stepBodyTemplate = (data) => {
    return <div>{parseFloat(data.step).toFixed(2)}</div>;
  };

  const timeBodyTemplate = (data) => {
    return <div>{parseFloat(data.time).toFixed(2)}</div>;
  };

  const buttonBodyTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2 ">
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
        <Button
          icon="pi pi-ellipsis-v"
          style={{
            background: "transparent",
            border: "0px",
            fontWeight: "bold",
            color: "white",
          }}
          className="mr-2"
          id="1"
          onClick={(event) => {
            menuLeft.current.toggle(event);
            setCurrent(data.user.player_id);
            setSuperVision(data.user.supervision_requests_id);
          }}
          aria-controls="popup_menu_left"
          aria-haspopup
        />
      </div>
    );
  };

  //tables templates ends here

  const header = renderHeader();
  return (
    <div className="card">
      <DataTable
        value={playersInfo}
        paginator
        rows={5}
        showGridlines
        tableStyle={{ minWidth: "50rem" }}
        dataKey="id"
        loading={playersloading}
        emptyMessage="No record found."
        filters={filters}
        globalFilterFields={[
          "name",
          "flex",
          "force",
          "runuptime",
          "step",
          "time",
        ]}
        header={header}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column field="name" header="Name" body={nameBodyTemplate} sortable />
        <Column field="flex" header="Flex" body={flexBodyTemplate} sortable />
        <Column
          field="force"
          header="Force"
          body={forceBodyTemplate}
          sortable
        />
        <Column
          field="time"
          header="Action Time"
          body={timeBodyTemplate}
          sortable
        />
        <Column field="step" header="Step" body={stepBodyTemplate} sortable />
        <Column
          field="runuptime"
          header="Runup Time"
          body={runuptimeBodyTemplate}
          sortable
        />
        <Column
          field="time"
          header="Runnup Speed"
          body={timeBodyTemplate}
          sortable
        />
        <Column
          field="time"
          header="Arm Twist"
          body={timeBodyTemplate}
          sortable
        />

        <Column body={buttonBodyTemplate} />
      </DataTable>
      {currentPlayer && visibleRight && (
        <div className="card">
          <Sidebar
            visible={visibleRight}
            position="right"
            onHide={() => setVisibleRight(false)}
          >
            <div className="text-center">
              <Avatar
                image={currentPlayer[0].user.picture}
                size="xlarge"
                shape="circle"
                className="mb-3"
                style={{ width: "100px", height: "100px" }}
              />
              <p className=" text-capitalize fw-bold mb-5">
                {currentPlayer[0].user.name}
              </p>
            </div>
            <ul class="list-group border-0 ">
              <li class="list-group-item border-0 border-bottom border-top text-capitalize p-3">
                <FontAwesomeIcon icon={faEnvelope} className="mx-2" />{" "}
                {currentPlayer[0].user.email}
              </li>
              <li class="list-group-item  border-0 border-bottom text-capitalize  p-3">
                <FontAwesomeIcon icon={faPhone} className="mx-2" />{" "}
                {currentPlayer[0].user.phone}
              </li>
              <li class="list-group-item  border-0 border-bottom text-capitalize p-3">
                <FontAwesomeIcon icon={faMale} className="mx-2 fs-2" />{" "}
                {currentPlayer[0].user.gender}
              </li>
              <li class="list-group-item  border-0 border-bottom text-capitalize p-3">
                <FontAwesomeIcon icon={faMedal} className="mx-2" />{" "}
                {currentPlayer[0].user.careerLevel} Career Level
              </li>
              <li class="list-group-item  border-0 border-bottom text-capitalize p-3">
                <FontAwesomeIcon icon={faRunning} className="mx-2" />{" "}
                {currentPlayer[0].user.bowlingArm} Bowling Arm
              </li>
              <li class="list-group-item  border-0 border-bottom text-capitalize p-3">
                <FontAwesomeIcon icon={faBowlingBall} className="mx-2" />{" "}
                {currentPlayer[0].user.bowlingStyle} Bowling Style
              </li>
              <li class="list-group-item  border-0 border-bottom p-3">
                <FontAwesomeIcon icon={faUserFriends} className="mx-2" />{" "}
                {currentPlayer[0].user.playerCategory} Player Category
              </li>
            </ul>
          </Sidebar>
        </div>
      )}
    </div>
  );
}
