export const coachDashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to:'/coachdashboard',
    }
  ]
};




export const tasksRoutes = {
  label: 'Tasks',
  // labelDisable: true,
  children: [
    {
      name: 'Tasks',
      icon: 'list',
      to: 'user/tasks',
      active: true
    },
  ]
};

export const devicesRoutes = {
  label: 'Devices',
  // labelDisable: true,
  children: [
    {
      name: 'Devices',
      icon: 'mobile',
      to: 'user/devices',
      active: true
    },
  ]
};

export const liveDataRoutes = {
  label: 'Live Tracking',
  // labelDisable: true,
  children: [
    {
      name: 'Live Tracking',
      icon: 'list',
      to: 'user/livedata',
      active: true
    },
    // {
    //   name: 'Bowling Overview',
    //   icon: 'chart-pie',
    //   to: 'user/overview',
    //   active: true
    // },
  ]
};

export const bowlingRoutes = {
  label: 'Bowling History',
  // labelDisable: true,
  children: [
    {
      name: 'Bowling History',
      icon: 'clock',
      to: 'user/bowlinghistory',
      active: true
    },
  ]
};
export const connectedPlayers = {
  label: 'Players',
  // labelDisable: true,
  children: [
    {
      name: 'Players',
      icon: 'link',
      to: '/user/connectedplayers',
      active: true
    },
  ]
};

export const ComparisonRoutes = {
  label: 'Player Comparison',
  // labelDisable: true,
  children: [
    {
      name: 'Player Comparison',
      icon: 'chart-line',
      to: '/user/comparison',
      active: true
    },
  ]
};

export const GroupRoutes = {
  label: 'Player Groups',
  // labelDisable: true,
  children: [
    {
      name: 'Player Groups',
      icon: 'users',
      to: '/user/groups',
      active: true
    },
  ]
};

export const VideoRoutes = {
  label: 'Add Video',
  // labelDisable: true,
  children: [
    {
      name: 'Video Lessons',
      icon: 'book',
      to: '/user/videos',
      active: true
    },
  ]
};



export default [
  coachDashboardRoutes,
  tasksRoutes,
  devicesRoutes,
  liveDataRoutes,
  bowlingRoutes,
  connectedPlayers,
  GroupRoutes,
  ComparisonRoutes,
  VideoRoutes
];
