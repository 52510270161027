import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Row, Col } from "react-bootstrap";
import axios from "../../api/axios";

const RegistrationForm = ({ hasLabel }) => {
  //State
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    coach: null,
    password: "",
    confirmPassword: "",
    access: "",
    success: false,
  });

  const [coaches, setCoaches] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const navigate = useNavigate();
  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const post = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      coach: formData.coach,
      access: formData.access,
    };
    if (post.access === "coach") {
      post.coach = null;
    }
    if (post.access == "0" || post.coach == "0") {
      toast.error(`ERROR! Invalid values selected!`, {
        theme: "colored",
      });
    } else {
      let res = null;
      setIsBusy(true);
      try {
        res = await axios.post("api/signup", post);
        setIsBusy(false);
        if (res.data.access_token && res.data.access_token.length !== 0) {
          localStorage.setItem(
            "access_token",
            JSON.stringify(res.data.access_token)
          );
          localStorage.setItem("user_info", JSON.stringify(res.data.user));

          const user_info = JSON.parse(localStorage.getItem("user_info"));
          const access_token = JSON.parse(localStorage.getItem("access_token"));
          const player = {
            player_id: user_info.id,
          };
          if (user_info.access === "player") {
            try {
              const res = await axios.post(
                "api/getAttachedSupervisorStatus",
                player,
                {
                  headers: { Authorization: `Bearer ${access_token}` },
                }
              );
              localStorage.setItem(
                "supervisor_status",
                JSON.stringify(res.data)
              );
            } catch (e) {
              if (e.response) {
                // console.log(e.response);
              }
            }
          }
          setFormData({ success: true });
          if (res.data.access === "player") {
            navigate("/playerdashboard");
          } else if (res.data.access === "coach") {
            navigate("/coachdashboard");
          } else if (res.status !== 200 || res.status !== 201) {
            toast.error(`ERROR! ${res.data.message}`, {
              theme: "colored",
            });
          }
        }
      } catch (e) {
        setIsBusy(false);

        if (e.response.data.error.email) {
          toast.error(`ERROR! ${e.response.data.error.email}`, {
            theme: "colored",
          });
        }
        if (e.response.data.error.phone) {
          toast.error(`ERROR! ${e.response.data.error.phone}`, {
            theme: "colored",
          });
        }
        if (e.response.data.error.password) {
          toast.error(`ERROR! ${e.response.data.error.password}`, {
            theme: "colored",
          });
        }
      }
    }
  };

  //Getting ALL Coaches
  useEffect(() => {
    (async () => {
      const response = await axios.get("api/listCoaches");
      const data = response.data;
      setCoaches(data);
    })();
  }, []);

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Name" : ""}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Phone Number</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Phone Number" : ""}
          value={formData.phone}
          name="phone"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Select role</Form.Label>}
        <Form.Select
          aria-label="Default select example"
          value={formData.access}
          onChange={handleFieldChange}
          name="access"
        >
          <option value="0">Select Role</option>
          <option value="player">Player</option>
          <option value="coach">Coach</option>
        </Form.Select>
      </Form.Group>

      {formData.access === "player" && (
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Select Coach</Form.Label>}
          <Form.Select
            aria-label="Default select example"
            value={formData.coach}
            onChange={handleFieldChange}
            name="coach"
          >
            <option value="0">Select Coach</option>
            {coaches.map((coach) => {
              return (
                <option key={coach.user_id} value={coach.user_id}>
                  {coach.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      )}

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Password" : ""}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Confirm Password" : ""}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.name ||
            !formData.email ||
            !formData.phone ||
            !formData.password ||
            !formData.access ||
            (formData.access == "player" && formData.coach == null) ||
            formData.password !== formData.confirmPassword ||
            isBusy
          }
        >
          Register
        </Button>
      </Form.Group>
      {/* <Divider>or register with</Divider>
      <SocialAuthButtons /> */}
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default RegistrationForm;
