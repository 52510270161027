import { React, useEffect } from "react";
import { Col, Button, Image } from "react-bootstrap";
import { Avatar } from "primereact/avatar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ComparisonCard({ bowlingData, player, removePlayer, status }) {
  return (
    <Col md="4">
      <div className="mt-3 bg-light rounded" style={{ maxHeight: "550px" }}>
        {player && bowlingData && status != 0 && (
          <>
            <Button
              variant="link"
              onClick={removePlayer}
              id={player.user.player_id}
              size="sm"
              className="rounded-circle p-1 px-2 text-dark m-1 mx-2"
              style={{ float: "right" }}
            >
              X
            </Button>
            <div className="d-flex  py-3 px-4">
              {/* <Avatar
                image={player.user.picture}
                shape="circle"
                size="xlarge"
              /> */}
              <Image
                src={player.user.picture}
                style={{ width: "30%", height: "10%" }}
                roundedCircle
                fluid
              />
              <p className="fs-1 fw-normal text-capitalize mx-3 font-monospace mt-3">
                {player.user.name}
              </p>
            </div>

            <ul class="list-group list-group-flush p-3">
              <li class="list-group-item">
                <b>Flex:</b>{" "}
                {bowlingData.flex != null
                  ? Number(bowlingData.flex.toFixed(2))
                  : 0}{" "}
              </li>
              <li class="list-group-item">
                <b>Force:</b>{" "}
                {bowlingData.force != null
                  ? Number(bowlingData.force.toFixed(2))
                  : 0}{" "}
              </li>
              <li class="list-group-item">
                <b>Time:</b>{" "}
                {bowlingData.time != null
                  ? Number(bowlingData.time.toFixed(2))
                  : 0}{" "}
              </li>
              <li class="list-group-item">
                <b>Steps:</b>{" "}
                {bowlingData.steps != null
                  ? Number(bowlingData.steps.toFixed(2))
                  : 0}{" "}
              </li>
              <li class="list-group-item">
                <b>RunTime:</b>{" "}
                {bowlingData.runuptime != null
                  ? Number(bowlingData.runuptime.toFixed(2))
                  : 0}{" "}
              </li>
              <li class="list-group-item">
                <b>Total Balls:</b>{" "}
                {bowlingData.totalBalls != null
                  ? Number(bowlingData.totalBalls.toFixed(2))
                  : 0}{" "}
              </li>
            </ul>
            <div className="p-3 ">
              <div className="d-inline mx-4">
                <Link
                  to={`/user/bowlinghistory/${player.user.player_id}`}
                  className="ml-3"
                  target="_blank"
                >
                  <FontAwesomeIcon className="fs-2 mt-1" icon={"clock"} />
                </Link>
              </div>
              <div className="d-inline">
                <Link
                  to={`/user/profile/${player.user.player_id}`}
                  target="_blank"
                >
                  <FontAwesomeIcon className="fs-2 mt-1" icon={"user"} />
                </Link>
              </div>
              {console.log(player)}
            </div>
          </>
        )}
      </div>
    </Col>
  );
}

export default ComparisonCard;
