import React, {useEffect} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Logo from '../components/common/Logo';
import Section from '../components/common/Section';
import { Outlet, useNavigate } from 'react-router-dom';

const AuthSimpleLayout = () => {
  //local storage
  const user_information = JSON.parse(localStorage.getItem('user_info'));
  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem("user_info") !== null){
      if(user_information.access === 'player'){
        navigate('playerdashboard');
      } 
      else if(user_information.access === 'coach'){
        navigate('coachdashboard');
      } 
      else if(user_information.access === 'admin'){
        navigate('admindashboard');
      } 
    }else{
      navigate("/");
    }
  }, []);
  
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={9} lg={7} xl={7} className="col-xxl-4">
          <Logo width="250" />
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthSimpleLayout;
