import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Row, Col } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";

function DeviceModal({ updated, id}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isBusy, setIsBusy] = useState(false);
  const [formData, setFormData] = useState({
    group_id: id,
    player_id: null,
  });

  const [playersloading, isPlayersLoading] = useState(false);
  const [playersInfo, setPlayersInfo] = useState([]);

  // get connected players
  useEffect(() => {
    (async () => {
      isPlayersLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      console.log("console here connected players ");
      isPlayersLoading(false);
      setPlayersInfo(response.data);
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const Group = {
      group_id: formData.group_id,
      player_id: formData.player_id,
    };

    try {
      const res = await axios.post("api/assignPlayerToGroup", Group, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      updated(Math.random());
      setShow(false);
      setIsBusy(false);
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      setIsBusy(false);
      if (e.response) {
        toast.error(`ERROR! ${e.response.data.error}`, {
          theme: "colored",
        });
      }
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(formData);
  };

  return (
    <>
      <Button variant="link" onClick={handleShow} size="sm" >
        Assign Player
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">Assign Player to Group </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
                <Form.Label>Select player</Form.Label>
                <Form.Select
                  disabled={playersloading}
                  name="player_id"
                  value={formData.player_id}
                  onChange={handleFieldChange}
                >
                  <option>No player</option>
                  {playersInfo.map((player) => {
                    return <option value={player.user.player_id}>{player.user.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>

            <Form.Group className="mb-4">
              <Button
                className="w-100"
                type="submit"
                disabled={!formData.player_id || isBusy}
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeviceModal;
