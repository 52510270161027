import React from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

const ButtonBodyTemplate = ({ data, menuLeft, items, setCurrent }) => {
  return (
    <div className="flex align-items-center gap-2 ">
      <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
      <Button
        icon="pi pi-ellipsis-v"
        link
        style={{
          padding: "4px",
          background: "transparent",
          border: "0px",
          fontWeight: "bold",
          color: "white",
        }}
        className="mr-2"
        id="1"
        onClick={(event) => {
          menuLeft.current.toggle(event);
          setCurrent(data.user_id);
        }}
        aria-controls="popup_menu_left"
        aria-haspopup
      />
    </div>
  );
};

export default ButtonBodyTemplate;