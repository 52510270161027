import React, { useState, useEffect } from "react";
import axios from "api/axios";
import TaskModal from "components/pages/tasks/TaskModal";
import PlayersModal from "components/pages/tasks/PlayersModal";
import GroupModal from "components/pages/tasks/GroupModal";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Badge,
  Accordion,
  Form,
} from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

const Tasks = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [taskList, setTaskList] = useState([]); //stores all tasks
  const [playerList, setPlayerList] = useState([]); //stores all players
  const [groupData, setGroupData] = useState([]); //stores groups with task, players
  const [groupList, setGroupList] = useState([]); //stores all player groups
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [updated, setUpdated] = useState(0);

  const [formData, setFormData] = useState({
    date: 0,
    status: "NA",
  });

  //access control
  useEffect(() => {
    if (user_info.access == "admin") {
      navigate("/Admindashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  //delete Task
  const deleteTask = async (id) => {
    if (window.confirm("Are you sure you want to delete task?")) {
      try {
        const res = await axios.get(`api/deleteTask/${id}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        console.log(res);
        if (res) {
          setUpdated(Math.random());
        }
        if (res.status === 201) {
          toast.success(`SUCCESS! ${res.data.message}`, {
            theme: "colored",
          });
        }
      } catch (e) {
        if (e.response) {
          toast.error(`${e.response.data.error}`, {
            theme: "colored",
          });
        }
      }
    }
  };
  // get all tasks
  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = null;
      if (user_info.access === "player") {
        response = await axios.get(
          `api/getAllTasksPlayer/${user_info.id}/0/NA`,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );
      } else {
        response = await axios.get(`api/getAllTasks/${user_info.id}/0/NA`, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
      }

      setLoading(false);
      setTaskList(response.data);
    })();
  }, [updated]);

  //submitting filters
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response = null;
    if (user_info.access == "coach") {
      response = await axios.get(
        `api/getAllTasks/${user_info.id}/${formData.date}/${formData.status}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
    } else if (user_info.access == "player") {
      response = await axios.get(
        `api/getAllTasksPlayer/${user_info.id}/${formData.date}/${formData.status}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
    }

    setLoading(false);
    setTaskList(response.data);
  };

  // get connected players
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      setLoading(false);
      var user = response.data.map((data) => {
        return data.user;
      });
      setPlayerList(user);
    })();
  }, []);

  //get player groups
  useEffect(() => {
    (async () => {
      setLoading(true);
      const Coach = {
        coach_id: user_info.id,
      };
      const response = await axios.post(`api/getGroupData`, Coach, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setLoading(false);
      const groups = response.data.map((item) => {
        return item.group;
      });
      setGroupList(groups);
      setGroupData(response.data);
    })();
  }, []);

  //complete pending task
  const completeTask = async (e) => {
    e.preventDefault();
    const status = {
      task_id: e.target.id,
      player_id: user_info.id,
      status: "Complete",
    };

    try {
      const res = await axios.post("api/updateTaskStatus", status, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      if (res) {
        setUpdated(Math.random());
      }
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  //approve completed tasks coach
  const approveTask = async (e) => {
    e.preventDefault();
    const status = {
      task_id: e.target.id,
      player_id: user_info.id,
      status: "approved",
    };

    try {
      const res = await axios.post("api/updateTaskStatus", status, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      if (res) {
        setUpdated(Math.random());
      }
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  //inprogressTask pending task
  const inprogressTask = async (e) => {
    e.preventDefault();
    const status = {
      task_id: e.target.id,
      player_id: user_info.id,
      status: "Inprogress",
    };

    try {
      const res = await axios.post("api/updateTaskStatus", status, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      if (res) {
        setUpdated(Math.random());
      }
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  return (
    <>
      {user_info.access === "coach" && <TaskModal updated={setUpdated} />}

      <Row className="mb-2  p-3">
        <Col className="bg-light p-3 rounded shadow">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="4" className="mt-2 ">
                <p>Date</p>
                <Form.Control
                  placeholder="Enter date"
                  value={formData.date}
                  name="date"
                  onChange={handleFieldChange}
                  type="Date"
                  size="sm"
                />
              </Col>
              <Col md="3" className="mt-2">
                <p>Status</p>
                <Form.Select
                  aria-label="Default select example"
                  value={formData.status}
                  onChange={handleFieldChange}
                  name="status"
                  size="sm"
                  disabled={loading}
                >
                  <option value={"Pending"}>{"Pending"}</option>
                  <option value={"Complete"}>{"Complete"}</option>
                  <option value={"Inprogress"}>{"Inprogress"}</option>
                  <option value={"Approved"}>{"Approved"}</option>
                </Form.Select>
              </Col>
              <Col md="1" className="">
                <Button
                  type="submit"
                  size="sm"
                  id="btn"
                  className="btn btn-primary mt-5 text-light"
                >
                  Apply{" "}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      {!loading ? (
        <>
          {taskList.length !== 0 ? (
            <>
              {user_info.access === "player" ? (
                <>
                  {taskList.map((taskData) => (
                    <>
                      <Card className="mb-3 d-flex">
                        <Card.Body>
                          <Card.Title className="text-capitalize">
                            <FontAwesomeIcon
                              icon="arrow-right"
                              className="me-2 text-900 fs-5"
                              style={{ height: "17px", width: "20px" }}
                            />{" "}
                            {taskData.title}
                          </Card.Title>
                          <hr />
                          <Container>
                            <Row>
                              <Col lg="8">
                                <Card.Text>
                                  <p>{taskData.description}</p>
                                  <p>
                                    <FontAwesomeIcon
                                      icon="clock"
                                      className="me-1 text-700 fs-3"
                                      style={{ height: "17px", width: "20px" }}
                                    />{" "}
                                    <b>Deadline Date: </b>
                                    <span className=" ">
                                      {taskData.deadline_date}
                                    </span>{" "}
                                    | <b>Deadline Time:</b>{" "}
                                    <span className=" me-3">
                                      {" "}
                                      {taskData.deadline_time}
                                    </span>
                                    | <b>Created At:</b>{" "}
                                    <span className=" me-3">
                                      {" "}
                                      {new Date(
                                        taskData.created_at
                                      ).toLocaleDateString()}
                                    </span>
                                  </p>
                                </Card.Text>
                              </Col>
                              <Col lg="4">
                                <div className="">
                                  {taskData.status === "Complete" ? (
                                    <>
                                      <Button
                                        variant="success"
                                        size="sm"
                                        id={taskData.id}
                                        className="me-2"
                                        onClick={completeTask}
                                        disabled
                                      >
                                        Complete
                                      </Button>
                                      <Button
                                        variant="warning"
                                        size="sm"
                                        id={taskData.id}
                                        onClick={inprogressTask}
                                        disabled
                                      >
                                        Inprogress
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        variant="success"
                                        size="sm"
                                        className="me-2"
                                        id={taskData.id}
                                        onClick={completeTask}
                                      >
                                        Complete
                                      </Button>

                                      <Button
                                        variant="warning"
                                        size="sm"
                                        id={taskData.id}
                                        onClick={inprogressTask}
                                        disabled
                                      >
                                        Inprogress
                                      </Button>
                                    </>
                                  )}
                                  <div className="mt-3">
                                    <b>{taskData.status}</b>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    </>
                  ))}
                </>
              ) : (
                <>
                  {taskList.map((taskData) => (
                    <>
                      <Card className="mb-3 d-flex">
                        <Card.Body>
                          <Card.Title className="text-capitalize">
                            <FontAwesomeIcon
                              icon="arrow-right"
                              className="me-2 text-900 fs-5"
                              style={{ height: "17px", width: "20px" }}
                            />{" "}
                            {taskData.tasks.title}
                          </Card.Title>
                          <hr />
                          <Container>
                            <Row>
                              <Col lg="10">
                                <Card.Text>
                                  <p>{taskData.tasks.description}</p>

                                  <Row>
                                    <Col lg="6">
                                      <Accordion
                                        style={{ maxWidth: "400px" }}
                                        className="border-0 my-0 "
                                      >
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            Assigned Players
                                          </Accordion.Header>
                                          <Accordion.Body className="border-0 my-0 ">
                                            <ListGroup as="ol">
                                              {taskData.player.length > 0 ? (
                                                <>
                                                  {taskData.player.map((pl) => {
                                                    return (
                                                      <ListGroup.Item
                                                        as="li"
                                                        className="d-flex justify-content-between align-items-start border-0"
                                                      >
                                                        <div className="ms-2 me-auto">
                                                          {pl.name}
                                                        </div>
                                                        {pl.status ===
                                                          "Pending" && (
                                                          <Badge
                                                            bg="primary"
                                                            pill
                                                          >
                                                            {pl.status}
                                                          </Badge>
                                                        )}
                                                        {pl.status ===
                                                          "Complete" && (
                                                          <Badge
                                                            bg="success"
                                                            pill
                                                          >
                                                            {pl.status}
                                                          </Badge>
                                                        )}
                                                        {pl.status ===
                                                          "InComplete" && (
                                                          <Badge
                                                            bg="warning"
                                                            pill
                                                          >
                                                            {pl.status}
                                                          </Badge>
                                                        )}
                                                      </ListGroup.Item>
                                                    );
                                                  })}
                                                </>
                                              ) : (
                                                <>No Players added yet!</>
                                              )}
                                            </ListGroup>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </Col>
                                  </Row>
                                  <hr />

                                  <p>
                                    <FontAwesomeIcon
                                      icon="clock"
                                      className="me-1 text-700 fs-3"
                                      style={{ height: "17px", width: "20px" }}
                                    />{" "}
                                    <b>Deadline Date: </b>
                                    <span className=" ">
                                      {taskData.tasks.deadline_date}
                                    </span>{" "}
                                    | <b>Deadline Time:</b>{" "}
                                    <span className="me-3">
                                      {" "}
                                      {taskData.tasks.deadline_time}
                                    </span>
                                    <b>Created At:</b>{" "}
                                    <span className=" me-3">
                                      {" "}
                                      {new Date(
                                        taskData.tasks.created_at
                                      ).toLocaleDateString()}
                                    </span>
                                  </p>
                                </Card.Text>
                              </Col>
                              <Col lg="6">
                                <div className="">
                                  <PlayersModal
                                    player={taskData.player}
                                    playerlist={playerList}
                                    task_id={taskData.tasks.id}
                                    updated={setUpdated}
                                  />
                                  <GroupModal
                                    player={taskData.groups}
                                    groupList={groupList}
                                    task_id={taskData.tasks.id}
                                    updated={setUpdated}
                                  />

                                  <Button
                                    variant="danger"
                                    size="sm"
                                    className="px-3 ms-2 mb-3"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteTask(taskData.tasks.id);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    </>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <p>No tasks to show!</p>
            </>
          )}
        </>
      ) : (
        <>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#F2F3F5"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ margin: "0 350px" }}
            wrapperClassName="justif-content-center"
            visible={true}
          />
        </>
      )}
    </>
  );
};

export default Tasks;
