import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppContext from "../context/Context";
import classNames from "classnames";
import NavbarTop from "../components/navbar/top/NavbarTop";
import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
import Footer from "../components/footer/Footer";
import ProductProvider from "../components/app/e-commerce/ProductProvider";
import CourseProvider from "../components/app/e-learning/CourseProvider";
import { Alert } from "react-bootstrap";
import axios from "api/axios";

const NoCoachLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes("kanban");
  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!access_token) {
      navigate("/");
    }
  });


  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      {(navbarPosition === "vertical" || navbarPosition === "combo") && (
        <NavbarVertical />
      )}
     
          <ProductProvider>
            <CourseProvider>
              <div className={classNames("content", { "pb-0": isKanban })}>
                <NavbarTop />
                {/*------ Main Routes ------*/}
                <Outlet />
                {!isKanban && <Footer />}
              </div>
            </CourseProvider>
          </ProductProvider>
    </div>
  );
};
export default NoCoachLayout;
