import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NotificationDropdown from '../../../components/navbar/top/NotificationDropdown';
import ProfileDropdown from '../../../components/navbar/top/ProfileDropdown';
import AppContext from '../../../context/Context';
import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';


const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
