export const developerDashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to:'/developerdashboard',
    }
  ]
};

export const devicesRoutes = {
  label: 'Devices',
  // labelDisable: true,
  children: [
    {
      name: 'Devices',
      icon: 'mobile',
      to: 'admin/devices',
      active: true
    },
  ]
};

export const BoardRoutes = {
  label: 'Cricket Board',
  // labelDisable: true,
  children: [
    {
      name: 'Register User',
      icon: 'user',
      to: 'admin/registeruser',
      active: true
    },
  ]
};

export const addVideo = {
  label: 'Add Video',
  // labelDisable: true,
  children: [
    {
      name: 'Add Video',
      icon: 'plus',
      to: '/admin/addvideo',
      active: true
    },
  ]
};

export const AllUsers = {
  label: 'All Users',
  // labelDisable: true,
  children: [
    {
      name: 'All Users',
      icon: 'users',
      to: '/admin/allusers',
      active: true
    },
  ]
};



export default [
  developerDashboardRoutes,
  devicesRoutes,
  BoardRoutes,
  AllUsers
];
