import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Container, Row, Col, Badge } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";

function GroupModal({ player, groupList, task_id, updated }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setIsChecked([]);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isChecked, setIsChecked] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //assigning checked ids
  const AssignedPlayers = () => {
    const group = player.map((group) => {
      return group.group_id;
    });
    console.log(group);
    if (player != null) {
      player.map((p) => {
        isChecked.push(p.group_id);
      });
      console.log(isChecked);
    }
    setShow(true);
  };

  //Assign Group to task
  const assignGroup = async (e) => {
    e.preventDefault();
    // let playersIds = isChecked.join();
    const assign = {
      group_id: isChecked.join(), //array of check group ids
      task_id: task_id,
    };

    try {
      const res = await axios.post("api/assignTaskToGroup", assign, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      if (res) {
        updated(Math.random());
        setIsChecked([]);
      }
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked && !isChecked.includes(parseInt(value))) {
      isChecked.push(parseInt(value));
    } else if (!checked && isChecked.includes(parseInt(value))) {
      setIsChecked(isChecked.filter((e) => e !== parseInt(value)));
      // isChecked.pop(parseInt(value));
    }
    console.log(isChecked);
  };

  return (
    <>
      <Button
        variant="info"
        size="sm"
        className="px-3 ms-2 mb-3"
        onClick={AssignedPlayers}
      >
        Assign Group
      </Button>

      {!isLoading ? (
        <>
          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title className="text-sm">
                Assign Groups to The Task
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={assignGroup}>
                {groupList.map((pl) => (
                  <>
                    <Container>
                      {isChecked.includes(pl.id) ? (
                        <>
                          <Row>
                            <Col lg="4">
                              <Form.Check
                                id={`default-check`}
                                label={pl.group_name}
                                className="mb-3"
                                value={pl.id}
                                defaultChecked
                                onChange={(e) => handleCheckbox(e)}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Form.Check
                            id={`default-check`}
                            label={pl.group_name}
                            className="mb-3"
                            value={pl.id}
                            onChange={(e) => handleCheckbox(e)}
                          />
                        </>
                      )}
                    </Container>
                  </>
                ))}
                <Form.Group>
                  <Button size="sm" type="submit">
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>Loading..</>
      )}
    </>
  );
}

export default GroupModal;
