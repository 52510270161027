import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Row, Col } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";

function VideoModal({ updated }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isBusy, setIsBusy] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    url: "",
    audience: "",
    tags: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const Video = {
      url: formData.url,
      name: formData.name,
      audience: formData.audience,
      tags: formData.tags
    };
    console.log(Video);
    try {
      const res = await axios.post("api/addVideo", Video, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      updated(Math.random());
      setShow(false);
      setIsBusy(false);
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      setIsBusy(false);
      if (e.response) {
        toast.error(`ERROR! ${e.response.data.error}`, {
          theme: "colored",
        });
      }
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="px-3 ms-2 mb-3"
        onClick={handleShow}
      >
        <FontAwesomeIcon
          icon="plus"
          className="me-2 text-700 fs-3"
          style={{ height: "17px", width: "20px" }}
        />
       Add Video
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">Add New Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            

            <Form.Group className="mb-3">
              <Form.Label>Video Name</Form.Label>
              <Form.Control
                placeholder="Enter Video Name"
                name="name"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Select Audience</Form.Label>
              <Form.Select
                  // disabled={playersloading}
                  name="audience"
                  value={formData.audience}
                  onChange={handleFieldChange}
                >
                  <option>Select Audience</option>
                  <option value="player">Player</option>
                  <option value="coach">Coach</option>
                  <option value="both">Both</option>
                </Form.Select>
            </Form.Group>

          

            <Form.Group className="mb-3">
              <Form.Label>Video Url</Form.Label>
              <Form.Control
                placeholder="Enter Youtube Video Url"
                name="url"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Comma Seperated Tags </Form.Label>
              <Form.Control
                placeholder="Enter tags"
                name="tags"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Button
                className="w-100"
                type="submit"
                disabled={
                  !formData.name ||
                  !formData.url ||
                  isBusy
                }
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VideoModal;
