import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar from "assets/img/team/2.jpg";
import Flex from "components/common/Flex";
import VerifiedBadge from "components/common/VerifiedBadge";
import React, { useState } from "react";
import { ListGroup, Image, Col, Row, Button} from "react-bootstrap";
import ProfileBanner from "../ProfileBanner";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from "react-loader-spinner";

const Banner = ({ profileinfo, user_info, access, loading }) => {
  return (
    <ProfileBanner>
      {loading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#F2F3F5"
          ariaLabel="three-dots-loading"
          wrapperStyle={{ margin: "0 auto" }}
          wrapperClassName="text-center"
          visible={true}
        />
      ) : (
        <>
        
          <ProfileBanner.Body>
            <Row className="justify-content-between">
              <Col lg={6}>
                <Image
                  width="180px"
                  rounded
                  src={
                    profileinfo.picture != "abc" ? profileinfo.picture : avatar
                  }
                  mediaClass="img-thumbnail shadow-sm "
                  className="mb-3"
                />

                <h4 className="mb-1">
                  {user_info.name} <VerifiedBadge />
                </h4>

                <p className="text-500">
                  <>
                    {profileinfo.city}, {profileinfo.country}
                  </>
                </p>
      
                {profileinfo.bowlingStyle && 
                <Button
                  variant="falcon-default"
                  size="sm"
                  className="px-3 ms-2"
                  onClick={() => window.open(`/user/bowlinghistory/${profileinfo.user_id}`, "_blank")}
                >
                  
                  <FontAwesomeIcon
                    icon={faClock}
                    className="me-1 text-700 fs-3"
                    style={{ height: "17px", width: "20px" }}
                  />{" "}
                  Bowling History
                </Button>
                }
                
                <div className="border-dashed border-bottom my-4 d-lg-none" />
              </Col>
              <Col className="ps-2 ps-lg-3 " lg={6}>
                <Flex alignItems="center" className="mb-2">
                  <FontAwesomeIcon
                    icon="user-circle"
                    className="me-2 text-700"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">Profile Information</h6>
                  </div>
                </Flex>

                {access === "player" && (
                  <>
                    <ListGroup variant="flush" className="border-left">
                      <ListGroup.Item>
                        <b>Career Level:</b> {profileinfo.careerLevel}{" "}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Club Team:</b> {profileinfo.club}{" "}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Gender:</b> {profileinfo.gender}{" "}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Bowling Arm:</b> {profileinfo.bowlingArm}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Bowling Style:</b> {profileinfo.bowlingStyle}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Height:</b> {profileinfo.height} cm
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Weight:</b> {profileinfo.weight} kg
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Category:</b> {profileinfo.playerCategory}
                      </ListGroup.Item>
                    </ListGroup>
                  </>
                )}

                {access === "coach" && (
                  <>
                    <ListGroup variant="flush" className="border-left">
                      <ListGroup.Item>
                        <b>Club Team:</b> {profileinfo.club}{" "}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Coaching Style:</b> {profileinfo.coachingStyle}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Gender:</b> {profileinfo.gender}
                      </ListGroup.Item>
                    </ListGroup>
                  </>
                )}
              </Col>
            </Row>
          </ProfileBanner.Body>
        </>
      )}
    </ProfileBanner>
  );
};

export default Banner;
