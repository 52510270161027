import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BallModal from "./BallModal";
import EditModal from "./EditModal";
import { faCaretUp, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Button as PrimeButton } from "primereact/button";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Form, Col, Row, Button } from "react-bootstrap";

export const actionTimeTemplate = (options, avgValues) => {
  return (
    <div
      className="py-3 px-2"
      style={{ minWidth: "100px" }}
      onClick={options.onClick}
    >
      {avgValues.ActionTime != null && (
        <>
          <small>Action Time</small>
          <div className="fs-1" style={{ fontWeight: 600 }}>
            {" "}
            <>{parseFloat(avgValues.ActionTime.avg).toFixed(2)}s</>
          </div>
          <small style={{ color: "#32CD32" }}>
            <FontAwesomeIcon icon={faCaretUp} />{" "}
            {parseFloat(avgValues.ActionTime.percentage).toFixed(2)}%
          </small>
        </>
      )}
    </div>
  );
};

export const ArmFlexTemplate = (options, avgValues) => {
  return (
    <div
      className="py-3 px-2 "
      style={{ minWidth: "100px" }}
      onClick={options.onClick}
    >
      {avgValues.flex != null && (
        <>
          <small>Arm Flex</small>
          <div className="fs-1" style={{ fontWeight: 600 }}>
            {" "}
            <>{parseFloat(avgValues.flex.avg).toFixed(2)}&deg;</>
          </div>
          <small style={{ color: "#32CD32" }}>
            <FontAwesomeIcon icon={faCaretUp} />{" "}
            {parseFloat(avgValues.flex.percentage).toFixed(2)}%
          </small>
        </>
      )}
    </div>
  );
};

export const ArmTwistTemplate = (options, avgValues) => {
  return (
    <div
      className="py-3 px-2 "
      style={{ minWidth: "100px" }}
      onClick={options.onClick}
    >
      {avgValues.flex != null && (
        <>
          <small>Arm Twist</small>
          <div className="fs-1" style={{ fontWeight: 600 }}>
            {" "}
            <>{parseFloat(avgValues.flex.avg).toFixed(2)}&deg;</>
          </div>
          <small style={{ color: "#32CD32" }}>
            <FontAwesomeIcon icon={faCaretUp} />{" "}
            {parseFloat(avgValues.flex.percentage).toFixed(2)}%
          </small>
        </>
      )}
    </div>
  );
};

export const forceTemplate = (options, avgValues) => {
  return (
    <div
      className="py-3 px-2 "
      style={{ minWidth: "100px" }}
      onClick={options.onClick}
    >
      {avgValues.force != null && (
        <>
          <small>Force</small>
          <div className="fs-1" style={{ fontWeight: 600 }}>
            {" "}
            <>{parseFloat(avgValues.force.avg).toFixed(2)}N</>
          </div>
          <small style={{ color: "#32CD32" }}>
            <FontAwesomeIcon icon={faCaretUp} />{" "}
            {parseFloat(avgValues.force.percentage).toFixed(2)}%
          </small>
        </>
      )}
    </div>
  );
};

export const runupTimeTemplate = (options, avgValues) => {
  return (
    <div
      className="py-3 px-2 "
      style={{ minWidth: "100px" }}
      onClick={options.onClick}
    >
      {avgValues.runnup != null && (
        <>
          <small>Runnup Time</small>
          <div className="fs-1" style={{ fontWeight: 600 }}>
            {" "}
            <>{parseFloat(avgValues.runnup.avg).toFixed(2)}s</>
          </div>
          <small style={{ color: "#32CD32" }}>
            <FontAwesomeIcon icon={faCaretUp} />{" "}
            {parseFloat(avgValues.runnup.percentage).toFixed(2)}%
          </small>
        </>
      )}
    </div>
  );
};

export const runupSpeedTemplate = (options, avgValues) => {
  return (
    <div
      className="py-3 px-2"
      style={{ minWidth: "100px" }}
      onClick={options.onClick}
    >
      {avgValues.runupspeed != null && (
        <>
          <small>Runnup Speed</small>
          <div className="fs-1" style={{ fontWeight: 600 }}>
            {" "}
            <>{parseFloat(avgValues.runupspeed.avg).toFixed(2)}</>
          </div>
          <small style={{ color: "#32CD32" }}>
            <FontAwesomeIcon icon={faCaretUp} />{" "}
            {parseFloat(avgValues.runupspeed.percentage).toFixed(2)}%
          </small>
        </>
      )}
    </div>
  );
};

export const RunupStepsTemplate = (options, avgValues) => {
  return (
    <div
      className="py-3 px-2"
      style={{ minWidth: "100px" }}
      onClick={options.onClick}
    >
      {avgValues.step != null && (
        <>
          <small>Runnup steps</small>
          <div className="fs-1" style={{ fontWeight: 600 }}>
            {" "}
            <>{parseFloat(avgValues.step.avg).toFixed(2)}</>
          </div>
          <small style={{ color: "#32CD32" }}>
            <FontAwesomeIcon icon={faCaretUp} />{" "}
            {parseFloat(avgValues.step.percentage).toFixed(2)}%
          </small>
        </>
      )}
    </div>
  );
};

export const timeTemplate = (history) => {
  const time = new Date(history.device_created_at);
  var date = time.getDate();
  var month = time.getMonth() + 1;
  var year = time.getFullYear();
  year = year.toString().substr(-2);
  var hour = time.getHours();
  var min = time.getMinutes();
  return <>{month + "/" + date + "/" + year + " " + hour + ":" + min}</>;
};

export const idTemplate = (history, props) => {
  return <>{props.rowIndex + 1}</>;
};

export const conditionsTemplate = (history) => {
  return (
    <>
      <BallModal pre={history.pre} post={history.post} />
    </>
  );
};

export const actionTemplate = (history, setUpdated, deleteBall) => (
  <>
    <Button
      variant="link"
      size="sm"
      onClick={(e) => {
        e.preventDefault();
        deleteBall(history.id);
      }}
    >
      <FontAwesomeIcon className="text-danger" icon={"trash"} />
    </Button>
    <EditModal history={history} setUpdated={setUpdated} />
    {/* <Link to={`/user/showvideo/`}>
        <FontAwesomeIcon icon={"edit"} />
      </Link> */}
  </>
);

export const legalTemplate = (rowData) => {
  return (
    <div className="flex align-items-center gap-2 ">
      {rowData.legal === "legal" ? (
        <>
          <span style={{ color: "green" }}>{rowData.legal}</span>
        </>
      ) : (
        <>
          <span style={{ color: "red" }}>{rowData.legal}</span>
        </>
      )}
    </div>
  );
};


export const renderHeader = (handleSubmit, user_info, formData, handleFieldChange, loading, players, player, formatedDate, btndisable, exportExcel, exportPdf) => {
  return (
    <Row className="mb-3">
      <Col md="8">
        <Form onSubmit={handleSubmit}>
          <Row>
            {user_info.access === "coach" && (
              <Col md="3" className="mt-2">
                Player
                <Form.Select
                  aria-label="Default select example"
                  value={formData.user_id}
                  onChange={handleFieldChange}
                  name="user_id"
                  size="sm"
                  disabled={loading}
                >
                  {players.map((player) => (
                    <>
                      <option value={player.user.player_id}>
                        {player.user.name}
                      </option>
                      ;
                    </>
                  ))}
                </Form.Select>
              </Col>
            )}

            {(user_info.access === "admin" ||
              user_info.access == "developer" ||
              user_info.access == "board") && (
              <Col md="3" className="mt-2">
                Player
                {player && (
                  <Form.Select
                    aria-label="Default select example"
                    value={formData.user_id}
                    onChange={handleFieldChange}
                    name="user_id"
                    size="sm"
                    disabled={true}
                  >
                    <option value={player.id}>{player.name}</option>
                  </Form.Select>
                )}
              </Col>
            )}
            <Col md="4" className="mt-2 ">
              From
              <Form.Control
                placeholder="Enter date"
                value={formData.from}
                name="from"
                onChange={handleFieldChange}
                type="Date"
                max={formatedDate}
                size="sm"
              />
            </Col>
            <Col md="4" className="mt-2 ">
              To
              <Form.Control
                placeholder="Enter date"
                value={formData.to}
                name="to"
                onChange={handleFieldChange}
                type="Date"
                max={formatedDate}
                size="sm"
              />
            </Col>
            <Col md="1" className="">
              <Button
                type="submit"
                size="sm"
                disabled={btndisable}
                id="btn"
                style={{ marginTop: "31px" }}
                className="btn btn-primary text-light"
              >
                Apply{" "}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col md="4" className="mt-4 d-flex justify-content-end">
        <div className="mx-3 ">
          <PrimeButton
            icon={
              <FontAwesomeIcon
                style={{
                  fontSize: "18px",
                  color: "#D3D3D3",
                  marginRight: "4px",
                }}
                icon={faFileExcel}
              />
            }
            label="Excel"
            onClick={exportExcel}
            className="py-1 px-3 "
            style={{
              color: "#D3D3D3",
              background: "#04AF70",
              borderColor: "#04AF70",
            }}
            data-pr-tooltip="XLS"
          />
          <PrimeButton
            icon={
              <FontAwesomeIcon
                style={{
                  fontSize: "18px",
                  color: "#D3D3D3",
                  marginRight: "4px",
                }}
                icon={faFilePdf}
              />
            }
            label="Pdf"
            onClick={exportPdf}
            className="py-1 px-3 mx-2"
            style={{
              color: "#D3D3D3",
              background: "#D70040",
              borderColor: "#D70040",
            }}
            data-pr-tooltip="XLS"
          />
        </div>
      </Col>
    </Row>
  );
};
