import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Row, Col } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";


function FirmwareModal({ updated, show, setShow, device_code }) {
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isBusy, setIsBusy] = useState(false);
  const [formData, setFormData] = useState({
    device_code: device_code,
    name:""
  });
  const [file, setFile] = useState();

  function handleFile(event) {
    setFile(event.target.files[0]);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const Firmware = {
      device_code: formData.device_code,
      name: formData.name,
      file: file,
    };
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${access_token}`,
      },
    };

    try {
      const res = await axios.post("api/uploadFirm", Firmware, config);
      updated(Math.random());
      setShow(false);
      setIsBusy(false);
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      setIsBusy(false);
      if (e.response) {
        toast.error(`ERROR! ${e.response.data.error.file}`, {
          theme: "colored",
        });
      }
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">Upload Firmware for {device_code}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3">
              <Form.Label>Firmware Name</Form.Label>
              <Form.Control
                placeholder="Enter Firmware name"
                name="name"
                type="text"
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Firmware .zip file</Form.Label>
              <Form.Control type="file" name="file" onChange={handleFile} />
            </Form.Group>
            
            <Form.Group className="mb-4">
              <Button
                className="w-100"
                type="submit"
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FirmwareModal;
