import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Row, Col } from "react-bootstrap";
import axios from "api/axios";
function PlayerModal({
  profile_info,
  userdata,
  onSubmit,
  setProfileSubmitted,
  profileSubmitted,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setLocationEdit(false);
  };
  const handleShow = () => setShow(true);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [statedisabled, setStateDisabled] = useState(false);
  const [citydisabled, setCityDisabled] = useState(false);
  const [locationEdit, setLocationEdit] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));

  const [formData, setFormData] = useState({
    user_id: profile_info.id,
    gender: profile_info.gender,
    birthday: profile_info.birthday,
    city: profile_info.city,
    state: profile_info.state,
    country: "",
    careerLevel: profile_info.careerLevel,
    weight: profile_info.weight,
    height: profile_info.height,
    bowlingArm: profile_info.bowlingArm,
    bowlingStyle: profile_info.bowlingStyle,
    playerCategory: profile_info.playerCategory,
    club: profile_info.club,
    phone: userdata.phone,
  });

  useEffect(() => {
    if (
      formData.careerLevel != "NA" &&
      formData.bowlingArm != "NA" &&
      formData.bowlingStyle != "NA" &&
      formData.playerCategory != "NA" &&
      formData.club != "NA" &&
      formData.birthday != "NA" &&
      formData.country != "NA" &&
      formData.state != "NA" &&
      formData.city != "NA" &&
      formData.gender != "NA"
    ) {
      setProfileSubmitted(true);
    }
  }, []);

  //get countries
  useEffect(() => {
    (async () => {
      const response = await axios.get(`api/getCountries`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log("console get counties ");
      console.log(response);
      setCountries(response.data);
    })();
  }, [locationEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const profile = {
      user_id: user_info.id,
      // picture: "abc",
      gender: formData.gender,
      birthday: formData.birthday,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      careerLevel: formData.careerLevel,
      weight: formData.weight,
      height: formData.height,
      bowlingArm: formData.bowlingArm,
      bowlingStyle: formData.bowlingStyle,
      playerCategory: formData.playerCategory,
      club: formData.club,
      phone: formData.phone,
    };
    onSubmit(profile);
    setShow(false);
  };

  const editLocation = () => {
    setLocationEdit(true);
    setFormData({
      country: "",
    });
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountry = (e) => {
    const selectedCountry = countries.find(
      (country) => country.name === e.target.value
    );
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    (async () => {
      setStateDisabled(true);
      const response = await axios.get(`api/getStates/${selectedCountry.id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log("console states");
      console.log(response);
      setStates(response.data);
      setStateDisabled(false);
    })();
  };

  const handleState = (e) => {
    var state_info = JSON.parse(e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    (async () => {
      setCityDisabled(true);
      const response = await axios.get(`api/getCities/${state_info.id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log("console cities ");
      console.log(response);
      setCities(response.data);
      setCityDisabled(false);
    })();
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="px-3 ms-2"
        onClick={handleShow}
      >
        <FontAwesomeIcon
          icon="user-circle"
          className="me-2 text-700 fs-3"
          style={{ height: "17px", width: "20px" }}
        />{" "}
        {profileSubmitted ? <>Edit Info</> : <>Add Info</>}
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">
            Update Profile Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Gender</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={formData.gender}
                onChange={handleFieldChange}
                name="gender"
              >
                <option>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                placeholder="Enter date"
                value={formData.birthday}
                name="birthday"
                onChange={handleFieldChange}
                type="Date"
              />
            </Form.Group>
            {!locationEdit ? (
              <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={4}>
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    onClick={() => setLocationEdit(true)}
                    name="country"
                    value={formData.country}
                  >
                    <option>{profile_info.country}</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} sm={4}>
                  <Form.Label>State</Form.Label>
                  <Form.Select onChange={handleState} name="state" disabled>
                    <option>
                      <option>{formData.state}</option>
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} sm={4}>
                  <Form.Label>City</Form.Label>
                  <Form.Select disabled>
                    <option>{formData.city}</option>
                  </Form.Select>
                </Form.Group>
              </Row>
            ) : (
              <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={4}>
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    onChange={handleCountry}
                    name="country"
                    value={formData.country}
                  >
                    <option selected>Select Country</option>
                    {countries.map((country, index) => (
                      <>
                        <option value={country.name} key={index}>
                          {country.name}
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} sm={4}>
                  <Form.Label>State</Form.Label>
                  <Form.Select
                    value={formData.state}
                    onChange={handleState}
                    name="state"
                    disabled={statedisabled}
                  >
                    {states.map((state, index) => (
                      <option value={JSON.stringify(state)} key={index}>
                        {state.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} sm={4}>
                  <Form.Label>City</Form.Label>
                  <Form.Select
                    value={formData.city}
                    onChange={handleFieldChange}
                    name="city"
                    disabled={citydisabled}
                  >
                    {cities.map((city, index) => (
                      <option value={city.name} key={index}>
                        {city.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Career Level</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={formData.careerLevel}
                onChange={handleFieldChange}
                name="careerLevel"
              >
                <option>Select Career Level</option>
                <option value="club">Club</option>
                <option value="national">National</option>
                <option value="international">International</option>
              </Form.Select>
            </Form.Group>

            <Row className="g-2 mb-3">
              <Form.Group as={Col} sm={6}>
                <Form.Label>Bowling Arm</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={formData.bowlingArm}
                  onChange={handleFieldChange}
                  name="bowlingArm"
                >
                  <option>Select Bowling Arm</option>
                  <option value="right">Right</option>
                  <option value="left">Left</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                <Form.Label>Select Bowling Style</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={formData.bowlingStyle}
                  onChange={handleFieldChange}
                  name="bowlingStyle"
                >
                  <option>Select Bowling Arm</option>
                  <option value="fast">Fast</option>
                  <option value="medium fast">Medium Fast</option>
                  <option value="leg break">Leg Break</option>
                  <option value="off break">Off break</option>
                  <option value="slow orthodox">Slow Orthodox</option>
                  <option value="slow chinaman">Slow Chinaman</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="g-2 mb-3">
              <Form.Group as={Col} sm={6}>
                <Form.Label>Weight</Form.Label>
                <Form.Control
                  placeholder="Enter weight "
                  value={formData.weight}
                  name="weight"
                  onChange={handleFieldChange}
                  type="number"
                />
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                <Form.Label>Height</Form.Label>
                <Form.Control
                  placeholder="Enter Height"
                  value={formData.height}
                  name="height"
                  onChange={handleFieldChange}
                  type="number"
                />
              </Form.Group>
            </Row>

            <Row className="g-2 mb-3">
              <Form.Group as={Col} sm={6}>
                <Form.Label>Player Category</Form.Label>
                <Form.Control
                  placeholder="Enter Player Category"
                  value={formData.playerCategory}
                  name="playerCategory"
                  onChange={handleFieldChange}
                  type="text"
                />
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                <Form.Label>Club</Form.Label>
                <Form.Control
                  placeholder="Enter Club Name"
                  value={formData.club}
                  name="club"
                  onChange={handleFieldChange}
                  type="text-"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  placeholder="Enter Phone"
                  value={formData.phone}
                  name="phone"
                  onChange={handleFieldChange}
                  type="text"
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-4">
              <Button className="w-100" type="submit">
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PlayerModal;
