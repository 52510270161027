import React from "react";
import { Navigate} from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const Dashboard = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  return (
    <>
      {user_info && (
        <>
          {user_info.access === "board" ? (
            <>
              <>
                <Row className="g-3 mb-3">
                  <p>Welcome to Cricket Board Dashboard</p>
                  {/* <Col md={6} xxl={3}>
                    <WeeklySales data={weeklySalesData} />
                  </Col>
                  <Col md={6} xxl={3}>
                    <TotalOrder data={totalOrder} />
                  </Col> */}
                </Row>

                <Row className="g-3 mb-3">
                  {/* <Col lg={6}>
                    <RunningProjects data={runningProjects} />
                  </Col>
                  <Col lg={6}>
                    <TotalSales data={totalSales} />
                  </Col> */}
                </Row>

                <Row className="g-3 mb-3">
                  {/* <Col lg={7} xl={8}>
                    <BestSellingProducts products={products} />
                  </Col>
                  <Col lg={5} xl={4}>
                    <SharedFiles files={files} className="h-lg-100" />
                  </Col> */}
                </Row>
              </>
            </>
          ) : (
            <>
              {" "}
              <Navigate to="/" />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
