import React, { useContext } from "react";
import NavbarDropdown from "./NavbarDropdown";
import {
  coachDashboardRoutes,
  tasksRoutes,
  devicesRoutes
} from "../../../routes/coachMaps";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { flatRoutes } from "../../../helpers/utils";
import AppContext from "../../../context/Context";

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig,
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig("navbarCollapsed", !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig("showBurgerMenu", !showBurgerMenu);
    }
  };
  const user_info = JSON.parse(localStorage.getItem("user_info"));

  return (
    <>
      <NavbarDropdown title="dashboard">
        {coachDashboardRoutes.children[0].children.map((route) => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? "link-600" : "text-500"}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
         

      {/* <NavbarDropdown title="app">
        <NavbarDropdownApp items={appRoutes.children} />
      </NavbarDropdown> */}

      {/* <NavbarDropdown title="pages">
        <NavbarDropdownPages items={pagesRoutes.children} />
      </NavbarDropdown> */}
      {/* <NavbarDropdown title="modules">
        <NavbarDropdownModules items={modulesRoutes.children} />
      </NavbarDropdown> */}

      <NavbarDropdown title="Tasks">
        {flatRoutes(tasksRoutes.children).map((route) => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? "link-600" : "text-500"}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
      <NavbarDropdown title="Devices">
        {flatRoutes(devicesRoutes.children).map((route) => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? "link-600" : "text-500"}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
