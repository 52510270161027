import React, { useState, useEffect, useRef } from "react";
import axios from "api/axios";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import AdminModal from "components/pages/devices/AdminModal";
import FirmwareAllModal from "components/pages/firmware/FirmwareAllModal";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { renderHeader, buttonBodyTemplate } from "./Templates";


const Devices = () => {
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [updated, setUpdated] = useState(0);
  const [current, setCurrent] = useState(null);
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  //access control
  useEffect(() => {
    if (user_info.access == "player" || user_info.access == "coach") {
      if (user_info.access == "player") navigate("/playerdashboard");
      else if (user_info.access == "coach") navigate("/coachdashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  //datatable xlsx and pdf exports
  const cols = [
    { field: "device_code", header: "Device Code" },
    { field: "device_mac", header: "Device Mac" },
    { field: "firmware_name", header: "Firmware Name" },
    { field: "firmware_url", header: "Firmware Url" },
    { field: "status", header: "Status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const allDevices = devices.map((devicedata) => {
    const data = {
      device_code: devicedata.device.device_code,
      device_mac: devicedata.device.mac,
      firmware_name: devicedata.firmware ? devicedata.firmware.name : "NA",
      firmware_url: devicedata.firmware ? devicedata.firmware.url : "NA",
      status: devicedata.firmware ? devicedata.firmware.status : "NA",
    };
    return data;
  });

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("l", 0, 0);
        doc.autoTable(exportColumns, allDevices);
        doc.save("users_export.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(allDevices);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "devices");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + "_export" + EXCEL_EXTENSION);
      }
    });
  };
  //datatable exports end here

  // data table filtering
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };


  // get admin Devices
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(`api/getDevicesListForAdmin`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setLoading(false);
      setDevices(response.data);
      console.log("Devices List");
      console.log(response.data);
    })();
  }, [updated]);

  //delete Device
  const deleteDevice = async () => {
    const Device = {
      device_id: current.device.id,
    };
    if (window.confirm("Are you sure you want to delete device?")) {
      try {
        const res = await axios.post("api/deleteDeviceFromAdminSide", Device, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        console.log(res);
        if (res) {
          setUpdated(Math.random());
        }
        if (res.status === 201) {
          toast.success(`SUCCESS! ${res.data.message}`, {
            theme: "colored",
          });
        }
      } catch (e) {
        if (e.response) {
          toast.error(`${e.response.data.error}`, {
            theme: "colored",
          });
        }
      }
    }
  };

  const items = [
    {
      label: "Upload Firmware",
      // icon: "pi pi-fw pi-trash",
      command: () => {
        handleShow();
      },
    },
    {
      label: "Delete Device",
      // icon: "pi pi-fw pi-trash",
      command: () => {
        deleteDevice();
      },
    },
    {
      label: "Download firmware",
      // icon: "pi pi-fw pi-eye",
      command: () => {
        current.firmware
          ? window.open(current.firmware.url)
          : alert("No firmware added for this device");
      },
    },
    {
      label: "Firmware History",
      // icon: "pi pi-fw pi-eye",
      command: () => {
        navigate(`/user/firmwarehistory/${current.device.device_code}`);
      },
    },
    {
      label: "Device Usage",
      // icon: "pi pi-fw pi-history",
      // command: () => {
      //   navigate(`/user/bowlinghistory/${current}`);
      // },
    },
  ];

  
  return (
    <>
      {user_info.access === "admin" && <AdminModal updated={setUpdated} />}
      {user_info.access === "admin" && (
        <FirmwareAllModal updated={setUpdated} />
      )}

      <DataTable
        paginator
        rows={5}
        showGridlines
        tableStyle={{ minWidth: "50rem" }}
        value={devices}
        loading={loading}
        filters={filters}
        globalFilterFields={[
          "device.device_code",
          "device.mac",
          "firmware.name",
        ]}
        header={renderHeader(globalFilterValue, onGlobalFilterChange, exportExcel, exportPdf)}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column header="Device Code" field="device.device_code"></Column>
        <Column header="Device Mac" field="device.mac"></Column>
        <Column header="Firmware Name" field="firmware.name" sortable></Column>
        <Column header="Action" body={(rowData) => buttonBodyTemplate(rowData, menuLeft, setCurrent, user_info, show, setShow, devices, setUpdated, items)}></Column>
      </DataTable>
    </>
  );
};

export default Devices;
