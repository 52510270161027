import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import FirmwareModal from "../firmware/FirmwareModal";
import { Menu } from "primereact/menu";

export const buttonBodyTemplate = (data, menuLeft, setCurrent, user_info, show, setShow, devices, setUpdated, items) => {
  return (
    <div className="flex align-items-center gap-2 ">
      {user_info.access === "admin" && (
        <>
          {show && (
            <FirmwareModal
              updated={setUpdated}
              show={show}
              setShow={setShow}
              device_code={devices.length > 0 && data.device.device_code}
            />
          )}
        </>
      )}
      <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
      <Button
        icon="pi pi-ellipsis-v"
        style={{
          background: "transparent",
          border: "0px",
          fontWeight: "bold",
          color: "white",
        }}
        className="mr-2"
        id="1"
        onClick={(event) => {
          menuLeft.current.toggle(event);
          setCurrent(data);
        }}
        aria-controls="popup_menu_left"
        aria-haspopup
      />
    </div>
  );
};

export const renderHeader = (globalFilterValue, onGlobalFilterChange, exportExcel, exportPdf) => {
  return (
    <div className="d-flex flex-row-reverse">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search Here"
          style={{ height: "45px" }}
        />
      </span>
      <div className="mx-3">
        <Button
          icon={
            <FontAwesomeIcon
              style={{
                fontSize: "18px",
                color: "#D3D3D3",
                marginRight: "4px",
              }}
              icon={faFileExcel}
            />
          }
          label="Excel"
          onClick={exportExcel}
          className="py-1 px-3 mt-2"
          style={{
            color: "#D3D3D3",
            background: "#04AF70",
            borderColor: "#04AF70",
          }}
          data-pr-tooltip="XLS"
        />
        <Button
          icon={
            <FontAwesomeIcon
              style={{
                fontSize: "18px",
                color: "#D3D3D3",
                marginRight: "4px",
              }}
              icon={faFilePdf}
            />
          }
          label="Pdf"
          onClick={exportPdf}
          className="py-1 px-3 mt-2 mx-2"
          style={{
            color: "#D3D3D3",
            background: "#D70040",
            borderColor: "#D70040",
          }}
          data-pr-tooltip="XLS"
        />
      </div>
    </div>
  );
};