import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Row, Col, Alert } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";
import TotalBalls from "./TotalBalls";
import { totalOrder, weeklySalesData } from "data/dashboard/default";
import LegalBalls from "./LegalBalls";
import Statistics from "./Statistics";
import { ThreeDots } from "react-loader-spinner";

const Dashboard = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [supervisorStatus, setSupervisorStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);
  const [lastMonthData, setLastMonthData] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const player = {
        player_id: user_info.id,
      };
      try {
        const res = await axios.post(
          "api/getAttachedSupervisorStatus",
          player,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );

        setSupervisorStatus(res.data);
        setLoading(false);
      } catch (e) {
        if (e.response) {
          // console.log(e.response);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const player = {
        user_id: user_info.id,
      };
      try {
        const res = await axios.post("api/playerDashboard", player, {
          headers: { Authorization: `Bearer ${access_token}` },
        });

        setLastMonthData(res.data);
        setLoading(false);
        console.log(lastMonthData);
      } catch (e) {
        if (e.response) {
          // console.log(e.response);
        }
      }
    })();
  }, []);

  return (
    <>
      {user_info && (
        <>
          {user_info.access === "player" ? (
            <>
              <>
                <Row className="g-3 mb-3">
                  <p>Welcome to Player Dashboard</p>
                  {!loading ? (
                    <>
                      {supervisorStatus === 0 && (
                        <Alert>
                          Warning! You are not connected to any coach.{" "}
                          <Alert.Link href="/user/coachlist">
                            Select Coach Now
                          </Alert.Link>
                          .
                        </Alert>
                      )}
                      <Col md={6} xxl={3}>
                        <TotalBalls data={weeklySalesData} stats={lastMonthData != undefined ?  (lastMonthData.legal + lastMonthData.ilegal): 0}/>
                      </Col>
                      <Col md={6} xxl={3}>
                        <LegalBalls data={totalOrder} stats={lastMonthData.legal} />
                      </Col>
                      <Col md={4} xxl={3}>
                        <Statistics
                          title={"Action Time"}
                          data={lastMonthData.ActionTime}
                        />
                      </Col>
                      <Col md={4} xxl={3}>
                        <Statistics
                          title={"Arm Flex"}
                          data={lastMonthData.flex}
                        />
                      </Col>
                      <Col md={4} xxl={3}>
                        <Statistics title={"Force"}
                        data={lastMonthData.force}
                        />
                      </Col>
                      <Col md={4} xxl={3}>
                        <Statistics title={"Runnup Steps"}
                        data={lastMonthData.step}
                        />
                      </Col>
                      <Col md={4} xxl={3}>
                        <Statistics title={"Runnup Time"}
                         data={lastMonthData.runnup}
                        />
                      </Col>
                      <Col md={4} xxl={3}>
                        <Statistics title={"Runnup Speed"}
                        data={lastMonthData.runupspeed}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <ThreeDots
                        color="#F2F3F5"
                        wrapperStyle={{ margin: "10px 200px" }}
                      />
                    </>
                  )}

                  {/* <Col md={6} xxl={3}>
                    <WeeklySales data={weeklySalesData} />
                  </Col>
                  <Col md={6} xxl={3}>
                    <TotalOrder data={totalOrder} />
                  </Col> */}
                </Row>

                <Row className="g-3 mb-3">
                  {/* <Col lg={6}>
                    <RunningProjects data={runningProjects} />
                  </Col>
                  <Col lg={6}>
                    <TotalSales data={totalSales} />
                  </Col> */}
                </Row>

                <Row className="g-3 mb-3">
                  {/* <Col lg={7} xl={8}>
                    <BestSellingProducts products={products} />
                  </Col>
                  <Col lg={5} xl={4}>
                    <SharedFiles files={files} className="h-lg-100" />
                  </Col> */}
                </Row>
              </>
            </>
          ) : (
            <>
              {" "}
              <Navigate to="/" />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
