import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "api/axios";
import { toast } from "react-toastify";
import * as echarts from "echarts";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { useNavigate, useParams, Link } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import {
  actionTimeTemplate,
  ArmFlexTemplate,
  ArmTwistTemplate,
  forceTemplate,
  runupTimeTemplate,
  runupSpeedTemplate,
  RunupStepsTemplate,
  timeTemplate,
  idTemplate,
  conditionsTemplate,
  actionTemplate,
  legalTemplate,
  renderHeader,
} from "./Templates";
const BowlingHistory = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [avgValues, setAvgValues] = useState([]);
  const [avgAvailable, setAVGAvailable] = useState(false);
  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [updated, setUpdated] = useState(0);
  const [btndisable, setBtnDisable] = useState(false);
  const [iscalled, setIsCalled] = useState(false);
  const date = new Date();

  const actionTimeData = history.map((data) => {
    return data.time;
  });

  const flexData = history.map((data) => {
    return data.flex;
  });

  const twistData = history.map((data) => {
    return data.flex;
  });

  const forceData = history.map((data) => {
    return data.force;
  });

  const runnupTimeData = history.map((data) => {
    return data.runuptime;
  });

  const runnupSpeedData = history.map((data) => {
    return data.speed;
  });

  const runnupStepsData = history.map((data) => {
    return data.step;
  });

  const dateData = history.map((data) => {
    const date = new Date(data.device_created_at).toLocaleDateString();
    const time = new Date(data.device_created_at).toLocaleTimeString();
    const dateTime = date + " " + time;
    return dateTime;
  });

  const formatDateString = (date) => {
    var formatted =
      date.getFullYear() +
      (date.getMonth() + 1 < 10 ? "-0" : "-") +
      (date.getMonth() + 1) +
      (date.getDate() < 10 ? "-0" : "-") +
      date.getDate();
    return formatted;
  };

  const subDaysfromDateString = (period) => {
    var date = new Date();
    var date2 = new Date();
    date2.setDate(date.getDate() - 30);
    const formatedDate = formatDateString(date2);
    return formatedDate;
  };

  const formatedDate = formatDateString(date);

  const { id } = useParams();
  const [formData, setFormData] = useState({
    from: subDaysfromDateString(),
    to: formatedDate,
    user_id: id != null ? id : user_info.id,
  });

  // //access control
  // useEffect(() => {
  //   if (user_info.access == "admin") {
  //     navigate("/Admindashboard");
  //     toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
  //       theme: "colored",
  //       toastId: "error1",
  //     });
  //   }
  // }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (
        (players.length > 0 && players != undefined) ||
        user_info.access === "player" ||
        user_info.access === "admin" ||
        user_info.access === "developer" ||
        user_info.access === "board"
      ) {
        const params = {
          user_id:
            user_info.access === "player"
              ? formData.user_id
              : id != null
              ? id
              : players[0].user.player_id,
        };
        try {
          const response = await axios.post(
            `api/bowlingHistoryLastThirtyDays`,
            params,
            {
              headers: { Authorization: `Bearer ${access_token}` },
            }
          );
          setLoading(false);
          setHistory(
            response.data.data.map((history, id) => {
              const force = history.force * 4;
              const armTwist = Math.floor(Math.random() * (40 - 20)) + 20;
              const distance = history.step * 1.25;
              const runupTime = (history.runuptime * 5) / 1000;
              const runupSpeed = (distance / runupTime) * 3.6;
              return {
                id: history.ball_id_final,
                flex: history.flex,
                twist: armTwist,
                force: force,
                time: history.time,
                step: history.step,
                distance: distance,
                runuptime: runupTime,
                speed: parseFloat(runupSpeed).toFixed(2),
                legal: history.flex > 15 ? "Illegal" : "legal",
                datatype: history.datatype,
                device_created_at: history.device_created_at,
                pre: history.pre,
                post: history.post,
              };
            })
          );
          setAvgValues({
            ActionTime: response.data.ActionTime,
            flex: response.data.flex,
            force: response.data.force,
            runnup: response.data.runnup,
            step: response.data.step,
            runupspeed: response.data.runupspeed,
          });

          setAVGAvailable(true);
          if (id == null) {
            setFormData({
              ...formData,
              user_id: players[0] ? players[0].user.player_id : user_info.id,
            });
          }
        } catch (e) {
          setLoading(false);
          console.log(e);
          toast.error(`ERROR! ${e}`, {
            theme: "colored",
          });
        }
      }
    })();
  }, [players, updated]);

  //generating excel file here
  const cols = [
    { field: "Serial", header: "Serial" },
    { field: "TimeStamp", header: "TimeStamp" },
    { field: "Time", header: "Time" },
    { field: "Flex", header: "Flex" },
    { field: "Twist", header: "Twist" },
    { field: "Force", header: "Force" },
    { field: "Runuptime", header: "Runuptime" },
    { field: "Speed", header: "Speed" },
    { field: "Step", header: "Step" },
    { field: "Legal/Illegal", header: "Legal/Illegal" },
    { field: "Datatype", header: "Datatype" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const users = history.map((historydata, id) => {
    const data = {
      Serial: id + 1,
      TimeStamp: historydata.device_created_at,
      Time: historydata.time,
      Flex: historydata.flex,
      Twist: historydata.twist,
      Force: historydata.force,
      Runuptime: historydata.runuptime,
      Speed: historydata.speed,
      Step: historydata.step,
      "Legal/Illegal": historydata.legal,
      Datatype: historydata.datatype,
    };

    return data;
  });

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const user_id =
          user_info.access === "player"
            ? formData.user_id
            : id != null
            ? id
            : players[0].user.player_id;
        const call_id = iscalled == true ? formData.user_id : user_id;
        var player = players.find((player) => {
          return player.user.player_id == call_id;
        });
        const doc = new jsPDF.default("l", "px", 0);
        if (user_info.access != "player") {
          doc.text(
            "Showing results from " +
              formData.from +
              " to " +
              formData.to +
              " for player " +
              player.user.name +
              "  @CricFlex",
            30,
            20
          );
        }

        doc.autoTable(exportColumns, users);
        doc.save("users_export.pdf");
      });
    });
  };

  const exportExcel = async () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(users);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "bowlingHistory");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + "_export" + EXCEL_EXTENSION);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const from = new Date(formData.from);
    const to = new Date(formData.to);
    if (from > to) {
      alert("Please enter correct dates to view results!");
    } else {
      setLoading(true);
      const params = {
        from: formData.from,
        to: formData.to,
        user_id: formData.user_id,
      };
      try {
        const response = await axios.post(`api/bowlingHistory`, params, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        setLoading(false);
        setHistory(
          response.data.data.map((history, id) => {
            const force = history.force * 4;
            const armTwist = Math.floor(Math.random() * (40 - 20)) + 20;
            const distance = history.step * 1.25;
            const runupTime = (history.runuptime * 5) / 1000;
            const runupSpeed = (distance / runupTime) * 3.6;
            return {
              flex: history.flex,
              twist: armTwist,
              force: force,
              time: history.time,
              step: history.step,
              distance: distance,
              runuptime: runupTime,
              speed: parseFloat(runupSpeed).toFixed(2),
              legal: history.flex > 15 ? "Illegal" : "legal",
              datatype: history.datatype,
              device_created_at: history.device_created_at,
              pre: history.pre,
              post: history.post,
            };
          })
        );
        setAvgValues({
          ActionTime: response.data.ActionTime,
          flex: response.data.flex,
          force: response.data.force,
          runnup: response.data.runnup,
          step: response.data.step,
          runupspeed: response.data.runupspeed,
        });
        setAVGAvailable(true);
        setIsCalled(true);
      } catch (e) {
        setLoading(false);
        if (e.response.data.error.from)
          toast.error(`ERROR! ${e.response.data.error.from}`, {
            theme: "colored",
          });

        if (e.response.data.error.to)
          toast.error(`ERROR! ${e.response.data.error.to}`, {
            theme: "colored",
          });
      }
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  //delete ball data
  const deleteBall = async (id) => {
    const ball = {
      id: id,
    };
    if (window.confirm("Are you sure you want to delete this ball record?")) {
      try {
        const res = await axios.post("api/deleteBowlingRecord", ball, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        console.log(res);

        if (res.status === 201) {
          toast.success(`SUCCESS! ${res.data.message}`, {
            theme: "colored",
          });
        }
      } catch (e) {
        if (e.response) {
          toast.error(`${e.response.data.error}`, {
            theme: "colored",
          });
        }
      }
    }
  };

  // player list for coach
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `api/getPlayerListForCoach/${user_info.id}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      setPlayers(response.data);
      setLoading(false);
    })();
  }, []);

  // player profile for admin
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(`api/getProfile/${formData.user_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setPlayer(response.data.user);
      setLoading(false);
    })();
  }, []);

  const options = {
    tooltip: {
      trigger: "axis",
      padding: [7, 10],
      position: function (pt) {
        return [pt[0], "10%"];
      },
      formatter: (params) => {
        const index = params[0].dataIndex;
        return `
        <div>ActionTime: ${actionTimeData[index] + "s"}</div>
          <div>Flex: ${flexData[index] + "&deg;"}</div>
          <div>twist: ${twistData[index] + "&deg;"}</div>
          <div>Force: ${forceData[index] + "N"}</div>
          <div>runnupTime: ${runnupTimeData[index] + "s"}</div>
          <div>RunnupSpeed: ${runnupSpeedData[index]}</div>
          <div>RunnupStep: ${runnupStepsData[index]}</div>
          <div>Bowled on: ${dateData[index]}</div>
        `;
      },
    },

    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      // axisLabel: {
      //   formatter: axisValue => {
      //     return new Date(axisValue, "YYYY-MM-DD HH:mm");
      //   }
      // },
      // type: "time",
      type: "category",
      boundaryGap: false,
      data: history.map((data) => {
        const date = new Date(data.device_created_at).toLocaleDateString();
        const time = new Date(data.device_created_at).toLocaleTimeString();
        const dateTime = date + " " + time;
        return dateTime;
      }),
    },
    yAxis: {
      type: "value",
      boundaryGap: false,
      splitLine: {
        lineStyle: {
          color: "gray",
        },
      },
    },
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 30,
      },
      {
        start: 0,
        end: 100,
      },
    ],
  };

  const flex = {
    series: [
      {
        name: "flex",
        type: "line",
        symbol: "circle",
        sampling: "lttb",
        markLine: {
          symbol: "none",
          data: [{ yAxis: 15, lineStyle: { color: "#f00", width: 2 } }],
        },
        itemStyle: {
          color: "rgb(255, 70, 131)",
          borderColor: "#EE9342",
          borderWidth: 3,
        },
        lineStyle: {
          color: "#3E83E5",
        },
        data: history.map((data) => {
          return data.flex;
        }),
      },
    ],
  };

  const twist = {
    series: [
      {
        name: "twist",
        type: "line",
        symbol: "circle",
        sampling: "lttb",
        itemStyle: {
          color: "rgb(255, 70, 131)",
          borderColor: "#EE9342",
          borderWidth: 3,
        },
        lineStyle: {
          color: "#3E83E5",
        },
        data: history.map((data) => {
          return data.flex;
        }),
      },
    ],
  };

  const force = {
    series: [
      {
        name: "force",
        type: "line",
        sampling: "lttb",
        symbol: "circle",
        itemStyle: {
          color: "rgb(255, 70, 131)",
          borderColor: "#EE9342",
          borderWidth: 3,
        },
        lineStyle: {
          color: "#3E83E5",
        },
        data: history.map((data) => {
          return data.force;
        }),
      },
    ],
  };

  const time = {
    series: [
      {
        name: "time",
        type: "line",
        sampling: "lttb",
        symbol: "circle",
        itemStyle: {
          color: "rgb(255, 70, 131)",
          borderColor: "#EE9342",
          borderWidth: 3,
        },
        lineStyle: {
          color: "#3E83E5",
        },
        data: history.map((data) => {
          return data.time;
        }),
      },
    ],
  };

  const runuptime = {
    series: [
      {
        name: "runup time",
        type: "line",
        sampling: "lttb",
        symbol: "circle",
        itemStyle: {
          color: "rgb(255, 70, 131)",
          borderColor: "#EE9342",
          borderWidth: 3,
        },
        lineStyle: {
          color: "#3E83E5",
        },
        data: history.map((data) => {
          return data.runuptime;
        }),
      },
    ],
  };

  const speed = {
    series: [
      {
        name: "speed",
        type: "line",
        sampling: "lttb",
        symbol: "circle",
        itemStyle: {
          color: "rgb(255, 70, 131)",
          borderColor: "#EE9342",
          borderWidth: 3,
        },
        lineStyle: {
          color: "#3E83E5",
        },
        data: history.map((data) => {
          return data.speed;
        }),
      },
    ],
  };

  const steps = {
    series: [
      {
        name: "steps",
        type: "line",
        sampling: "lttb",
        symbol: "circle",
        itemStyle: {
          color: "rgb(255, 70, 131)",
          borderColor: "#EE9342",
          borderWidth: 3,
        },
        lineStyle: {
          color: "#3E83E5",
        },
        data: history.map((data) => {
          return data.step;
        }),
      },
    ],
  };

  //calling header template for table header
  const header = renderHeader(
    handleSubmit,
    user_info,
    formData,
    handleFieldChange,
    loading,
    players,
    player,
    formatedDate,
    btndisable,
    exportExcel,
    exportPdf
  );
  return (
    <>
      <div className="card">
        <DataTable
          className="bg-red-500"
          stripedRows
          size={"small"}
          showGridlines
          tableStyle={{ minWidth: "50rem", fontSize: "15px" }}
          paginator
          rows={5}
          header={header}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={history}
          sortMode="multiple"
          scrollable
          loading={loading}
          scrollHeight="500px"
        >
          <Column field="id" body={idTemplate} header="Sr No" sortable></Column>
          <Column
            field="device_created_at"
            body={timeTemplate}
            header="Timestamp"
            sortable
          ></Column>
          <Column field="time" header="Action Time" sortable></Column>
          <Column field="flex" header="Arm Flex" sortable></Column>
          <Column field="twist" header="Arm Twist" sortable></Column>
          <Column field="force" header="Force" sortable></Column>
          <Column field="runuptime" header="Runup Time" sortable></Column>
          <Column field="speed" header="Runup Speed" sortable></Column>
          <Column field="step" header="Runup Steps" sortable></Column>
          <Column
            field="legal"
            header="Legal/Illegal"
            body={legalTemplate}
            sortable
          ></Column>
          <Column field="datatype" header="Datatype" sortable></Column>
          <Column header="Conditions" body={conditionsTemplate}></Column>
          {user_info.access == "admin" && (
            <Column
              header="Actions"
              style={{ minWidth: "100px" }}
              body={(history) =>
                actionTemplate(history, setUpdated, deleteBall)
              }
            ></Column>
          )}
        </DataTable>
      </div>
      <div className=" mt-5">
        {avgAvailable && (
          <TabView>
            <TabPanel
              headerTemplate={(options) =>
                actionTimeTemplate(options, avgValues)
              }
            >
              <ReactEChartsCore
                echarts={echarts}
                option={{ ...options, ...time }}
                notMerge={true}
                lazyUpdate={true}
              />
            </TabPanel>
            <TabPanel
              headerTemplate={(options) => ArmFlexTemplate(options, avgValues)}
            >
              <ReactEChartsCore
                echarts={echarts}
                option={{ ...options, ...flex }}
                notMerge={true}
                lazyUpdate={true}
              />
            </TabPanel>
            <TabPanel
              headerTemplate={(options) => ArmTwistTemplate(options, avgValues)}
            >
              <ReactEChartsCore
                echarts={echarts}
                option={{ ...options, ...twist }}
                notMerge={true}
                lazyUpdate={true}
              />
            </TabPanel>

            <TabPanel
              headerTemplate={(options) => forceTemplate(options, avgValues)}
            >
              <ReactEChartsCore
                echarts={echarts}
                option={{ ...options, ...force }}
                notMerge={true}
                lazyUpdate={true}
              />
            </TabPanel>
            <TabPanel
              headerTemplate={(options) =>
                runupTimeTemplate(options, avgValues)
              }
            >
              <ReactEChartsCore
                echarts={echarts}
                option={{ ...options, ...runuptime }}
                notMerge={true}
                lazyUpdate={true}
              />
            </TabPanel>
            <TabPanel
              header={"Runup Speed"}
              headerTemplate={(options) =>
                runupSpeedTemplate(options, avgValues)
              }
            >
              <ReactEChartsCore
                echarts={echarts}
                option={{ ...options, ...speed }}
                notMerge={true}
                lazyUpdate={true}
              />
            </TabPanel>
            <TabPanel
              headerTemplate={(options) =>
                RunupStepsTemplate(options, avgValues)
              }
            >
              <ReactEChartsCore
                echarts={echarts}
                option={{ ...options, ...steps }}
                notMerge={true}
                lazyUpdate={true}
              />
            </TabPanel>
          </TabView>
        )}
      </div>
    </>
  );
};

export default BowlingHistory;
