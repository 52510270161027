import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import axios from "api/axios";
import { FileUpload } from "primereact/fileupload";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { ProgressBar } from "primereact/progressbar";
function PicureUpload() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const chooseOptions = { label: "Choose" };
  const uploadOptions = { label: "Upload", className: "p-button-success" };
  const cancelOptions = { label: "Cancel", className: "p-button-danger" };
  
  const pictureUploadHandler = ({ files }) => {
    console.log(files);
    const [file] = files;
    uploadPicture(file);
  };

  const uploadPicture = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    try {
      if (!loading) {
        setLoading(true);
        const response = await axios.post(`api/uploadDp`, formData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setLoading(false);
        setShow(false);
        toast.success(`SUCCESS! ${response.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      setLoading(false);
      toast.error(`ERROR! ${e.response.data.error.file}`, {
        theme: "colored",
      });
    }
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="px-3 ms-2"
        onClick={handleShow}
      >
        <FontAwesomeIcon
          icon="plus"
          className="me-2 text-700 fs-3"
          style={{ height: "17px", width: "20px" }}
        />{" "}
        Upload Picture
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-sm">Upload Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#F2F3F5"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            </div>
          )}
          <div className="card">
            <FileUpload
              accept="image/*"
              name="file"
              // headerTemplate={headerTemplate}
              customUpload={true}
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
              uploadHandler={pictureUploadHandler}
              maxFileSize={1000000}
              value={100}
              emptyTemplate={
                <p className="m-0">
                  Drag and drop Profile Picture here to upload.
                </p>
              }
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PicureUpload;
