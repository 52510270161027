import React from "react";
import { Card } from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import Experience from "../Experience";
import { ThreeDots } from "react-loader-spinner";

const Coachdisplay = ({ coach, loading, access }) => {
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  const supervisor_status = JSON.parse(
    localStorage.getItem("supervisor_status")
  );
  return (
    <Card className="mb-3">
      {!loading ? (
        <>
          <FalconCardHeader title="Connected Coach" light />

          {coach.length != 0 && supervisor_status !== 0 && supervisor_status != 4 ? (
            <>
              <Card.Body className="fs--3">
                <Experience key={coach.id} coach={coach} access={access} />
              </Card.Body>
            </>
          ) : (
            <>
              <p className="p-3">Currently no coach connected!</p>
            </>
          )}
        </>
      ) : (
        <>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#F2F3F5"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ margin: "0 auto" }}
            wrapperClassName="text-center"
            visible={true}
          />
        </>
      )}
    </Card>
  );
};

export default Coachdisplay;
