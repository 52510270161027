import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Alert } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { pricingCoach } from "data/pricingCoach";
import { pricingPlayer } from 'data/pricingPlayer';

function PaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [plan, setPlan] = useState(findSelectedPlan());
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [formData, setFormData] = useState({
    user_id: user_info != null ? user_info.id: 1,
    name: user_info != null ? user_info.name: " ",
    plan: plan.plan,
    price: plan.price_id,
  });
  const [clientSecret, setClientSecret] = useState(null);

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function findPlan(pricing) {
    return pricing.id == id;
  }
  function findSelectedPlan() {
    if(user_info != null){
      return user_info.access === "player"? pricingPlayer.find(findPlan):pricingCoach.find(findPlan)
    } 
    return null;
  }

  useEffect(() => {
    (async () => {
      const response = await axios.get(`api/getpaymentintent/${user_info.id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      console.log(response);
      setClientSecret(response.data.client_secret);
    })();
  }, [plan]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const paymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    const subscription = {
      user_id: formData.user_id,
      name: formData.name,
      plan: formData.plan,
      price: formData.price,
      paymentMethod: paymentMethod.paymentMethod.id,
    };
    console.log(subscription);
    try {
      const res = await axios.post("api/user/subscribe", subscription, {
        headers: { Authorization: `Bearer ${access_token}` },
        origin: ["http://13.200.75.26:8080", "https://checkout.stripe.com"],
      });
      console.log(res);
      setIsLoading(false);
      if (res.status === 200) {
        var subscribed = true;
        localStorage.setItem("subscribed", JSON.stringify(subscribed));
        setFormData({
          name: "",
          email: "",
        });
        elements.getElement(CardElement).clear();
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      toast.error(`ERROR! ${e.response.data.message}`, {
        theme: "colored",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <PaymentElement /> */}
      {plan != null && (
        <Alert>
          You choosed{" "}
          <b>
            {plan.title} {plan.featureTitle}
          </b>{" "}
          subscription plan for <b>${plan.price}</b> only
        </Alert>
      )}

      <input type="hidden" name="plan" id="plan" value="prod_OMes4EbB723Lm7" />
      <input
        type="hidden"
        name="price"
        id="price"
        value="price_1NZvYKLPp6wETtzz88gI39wu"
      />
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          placeholder="Name"
          value={formData.name}
          name="name"
          style={{ border: "1px solid gray" }}
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3 ">
        <Form.Label>Payment Details</Form.Label>

        <div className=" p-2 rounded" style={{ border: "1px solid gray" }}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "white",
                  border: "2px solid white",
                  "::placeholder": {
                    color: "light-gray",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          data-secret={clientSecret}
          disabled={!stripe || !formData.name || isLoading}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="m-1"
            />
          ) : (
            "Pay Now"
          )}
        </Button>
      </Form.Group>
    </form>
  );
}

export default PaymentForm;
