import React, { useState, useEffect } from "react";
import axios from "api/axios";
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Form,
  Button,
} from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import io from "socket.io-client";
import { InputTextarea } from "primereact/inputtextarea";
// import classNames from "classnames";

const LiveBallData = () => {
  const [connectionStatus, setConnectionStatus] = useState("Not Connected");
  const [displayData, setDisplayData] = useState({
    flex: 0,
    force: 0,
    time: 0,
    steps: 0,
    runTime: 0,
  });

  const [totalBalls, setTotalBowls] = useState(0);
  const [legalBalls, setLegalBowls] = useState(0);
  const [illegalBalls, setillegalBowls] = useState(0);
  const [playerName, setPlayerName] = useState("NA");
  const [device, setDevice] = useState("NA");
  const [ballId, setBallId] = useState();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    pre: "",
    post: "",
  });
  var socket = null;
  const [show, setShow] = useState(2); //  0 = don't show 1 = show 2 = hide after 5s
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isChecked, setIsChecked] = useState(false);
  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmitPre = async (e) => {
    e.preventDefault();
    const conditions = {
      ball_id: ballId,
      pre: formData.pre,
      post: "NA",
      type: "pre",
    };

    try {
      const res = await axios.post("api/ballComments", conditions, {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      if (e.response.data.error.ball_id) {
        toast.error(`ERROR! ${e.response.data.error.ball_id}`, {
          theme: "colored",
        });
      }
      if (e.response.data.error.pre) {
        toast.error(`ERROR! ${e.response.data.error.pre}`, {
          theme: "colored",
        });
      }
      if (e.response.data.error.post) {
        toast.error(`ERROR! ${e.response.data.error.post}`, {
          theme: "colored",
        });
      }
    }
  };

  const autoSubmitPre = async () => {
    const conditions = {
      ball_id: ballId,
      pre: formData.pre,
      post: "NA",
      type: "pre",
    };

    try {
      const res = await axios.post("api/ballComments", conditions, {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      if (e.response.data.error.ball_id) {
        toast.error(`ERROR! ${e.response.data.error.ball_id}`, {
          theme: "colored",
        });
      }
      if (e.response.data.error.pre) {
        toast.error(`ERROR! ${e.response.data.error.pre}`, {
          theme: "colored",
        });
      }
      if (e.response.data.error.post) {
        toast.error(`ERROR! ${e.response.data.error.post}`, {
          theme: "colored",
        });
      }
    }
  };

  const handleSubmitPost = async (e) => {
    e.preventDefault();
    const conditions = {
      ball_id: ballId,
      pre: "NA",
      post: formData.post,
      type: "post",
    };

    try {
      const res = await axios.post("api/ballComments", conditions, {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      toast.error(`ERROR! ${e}`, {
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (show == 2) {
      setTimeout(() => {
        setShow(0);
      }, 5000);
    }
  }, [show]);

  useEffect(() => {
    socket = io("http://13.200.75.26:3001", {
      allowEIO3: true,
    });
    socket.on("displayConnectionStatus", (message) => {
      console.log(message);
      if (message == "Scanning") setShow(1);
      if (message == "Connected") setShow(2);
      console.log(connectionStatus);
      setConnectionStatus(message);
    });

    return () => {
      socket.off("displayConnectionStatus");
    };
  }, []);

  useEffect(() => {
    socket.on("displayDisplayData", (message) => {
      console.log(message);
      setLoading(true);
      setDisplayData({
        flex: message.ball_data.Flex,
        force: message.ball_data.Force,
        time: message.ball_data.Time,
        steps: message.ball_data.Steps,
        runTime: message.ball_data.RunTime,
      });

      setPlayerName(message.player_name);
      setDevice(message.device_code);
      setBallId(message.ball_id);
      setTotalBowls(message.total_balls);
      setLegalBowls(message.legal_balls);
      setillegalBowls(message.illegal_balls);

      setLoading(false);
    });

    return () => {
      socket.off("displayDisplayData");
    };
  }, []);

  useEffect(() => {
    if (isChecked) {
      autoSubmitPre();
    }
  }, [ ballId, illegalBalls, totalBalls, legalBalls]);

  return (
    <>
      <Container>
        <Row className="text-center mb-4 bg-light p-3 rounded shadow-lg">
          <Col md="4">
            <form onSubmit={handleSubmitPre}>
              <InputTextarea
                placeholder="Pre Ball Conditions"
                name="pre"
                value={formData.pre}
                onChange={handleFieldChange}
                rows={2}
                cols={40}
              />
              <Form.Check
                inline
                label="Auto Save"
                name="auto"
                checked={isChecked}
                onChange={handleCheckBox}
              />
              <Button
                type="submit"
                className="m-2"
                variant="primary"
                size="sm"
                disabled={isChecked || ballId == 0 || !formData.pre}
              >
                Save
              </Button>
            </form>
          </Col>
          <Col md="4">
            <h3>Live Ball Data</h3>
            <p className="fs-1">
              {playerName} - {device}
            </p>
            {connectionStatus == "Scanning" && (
              <Badge
                pill
                bg="warning"
                style={{ "font-size": "15px", fontWeight: "initial" }}
              >
                <FontAwesomeIcon icon={faWifi} /> {connectionStatus}
              </Badge>
            )}

            {connectionStatus == "Connecting" && (
              <Badge
                pill
                bg="primary"
                style={{ "font-size": "15px", fontWeight: "initial" }}
              >
                <FontAwesomeIcon icon={faWifi} /> {connectionStatus}
              </Badge>
            )}
            {show == 1 ||
              (show == 2 && (
                <>
                  {connectionStatus == "Connected" && (
                    <Badge
                      pill
                      bg="success"
                      style={{ "font-size": "15px", fontWeight: "initial" }}
                    >
                      <FontAwesomeIcon icon={faWifi} /> {connectionStatus}
                    </Badge>
                  )}
                </>
              ))}
          </Col>
          <Col md="4">
            <form onSubmit={handleSubmitPost}>
              <InputTextarea
                placeholder="Post Ball Conditions"
                name="post"
                value={formData.post}
                onChange={handleFieldChange}
                rows={2}
                cols={40}
              />
              <Button
                type="submit"
                className="m-2"
                variant="primary"
                size="sm"
                disabled={ballId == 0 || !formData.post}
              >
                Save
              </Button>
            </form>
          </Col>
        </Row>
        <Row className=" text-center">
          <Col md={4} className="mb-4">
            <Card className="p-2">
              <Card.Body>
                <Card.Title className="fs-3"> {displayData.flex}</Card.Title>
                <p>Flex</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="p-2">
              <Card.Body>
                <Card.Title className="fs-3"> {displayData.force} </Card.Title>
                <p>Force</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="p-2">
              <Card.Body>
                <Card.Title className="fs-3"> {displayData.time} </Card.Title>
                <p>Time</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="p-2">
              <Card.Body>
                <Card.Title className="fs-3"> {displayData.steps} </Card.Title>
                <p>Steps</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="p-2">
              <Card.Body>
                <Card.Title className="fs-3">{displayData.runTime}</Card.Title>
                <p>RunTime</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="2" className="mt-3 text-center"></Col>
          <Col md="4" className="mt-3 text-center">
            <h1 className=" fs-6">{totalBalls != 0 ? totalBalls : 0}</h1>
            <p className=" fs-3">TOTAL BALLS</p>
          </Col>
          <Col md="6">
            <Row>
              <Col md="1">
                <h1 className=" fs-6 text-success">
                  {legalBalls != 0 ? legalBalls : 0}
                </h1>
              </Col>
              <Col md="9">
                <p className="mt-2 fs-3">Legal</p>
              </Col>
            </Row>
            <Row>
              <Col md="1">
                <h1 className=" fs-6 text-danger">
                  {illegalBalls != 0 ? illegalBalls: 0}
                </h1>
              </Col>
              <Col md="9">
                <p className="mt-2 fs-3">Illegal</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LiveBallData;
