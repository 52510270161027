import freeIcon from "assets/img/icons/free.svg";
import proIcon from "assets/img/icons/pro.svg";

export const pricingCoach = [
  {
    id: 3,
    title: "Professional Tier 1",
    // subTitle: 'For organizations that need additional security and support.',
    price: 99,
    price_id: "price_1NZvYKLPp6wETtzz88gI39wu",
    plan:"professional_tier_1",
    url: "/user/subscription-payment/3",
    buttonText: "Subscribe",
    isFeatured: false,
    featureTitle: "CricTHROW+",
    features: [
      { id: 1, title: "50 users can use" },
      { id: 1, title: "Multiple Player History" },
      {
        id: 2,
        title: "Player Comparison",
        tag: { label: "Coming soon", type: "primary" },
      },
      { id: 3, title: "Connect Multiple Devices" },
      { id: 4, title: "Advance Analytics" },
      { id: 5, title: "Interface With Player/Coach" },
      { id: 6, title: "Metrics correlation" },
      { id: 7, title: "Assign Tasks" },
      { id: 8, title: "data Filters, sort & export" },
    ],
  },
  {
    id: 4,
    title: 'Professional Tier 2',
    // subTitle: 'For organizations that need additional security and support.',
    price: 175,
    price_id: "price_1NZvYKLPp6wETtzz88gI39wu",
    plan:"professional_tier_2",
    url: '/user/subscription-payment/4',
    buttonText: 'Subscribe',
    isFeatured: true,
    featureTitle: 'CricCoach',
    features: [
      { id: 1, title: "100 users can use" },
      { id: 1, title: "Multiple Player History" },
      {
        id: 2,
        title: "Player Comparison",
        tag: { label: "Coming soon", type: "primary" },
      },
      { id: 3, title: "Connect Multiple Devices" },
      { id: 4, title: "Advance Analytics" },
      { id: 5, title: "Interface With Player/Coach" },
      { id: 6, title: "Metrics correlation" },
      { id: 7, title: "Assign Tasks" },
      { id: 8, title: "data Filters, sort & export" },
    ]
  },
  {
    id: 5,
    title: 'Professional Tier 3',
    // subTitle: 'For organizations that need additional security and support.',
    price: 99,
    price_id: "price_1NZvYKLPp6wETtzz88gI39wu",
    plan:"professional_tier_3",
    url: '/user/subscription-payment/5',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: 'CricBoard',
    features: [
      { id: 1, title: "unlimited users can use" },
      { id: 1, title: "Multiple Player History" },
      {
        id: 2,
        title: "Player Comparison",
        tag: { label: "Coming soon", type: "primary" },
      },
      { id: 3, title: "Connect Multiple Devices" },
      { id: 4, title: "Advance Analytics" },
      { id: 5, title: "Interface With Player/Coach" },
      { id: 6, title: "Metrics correlation" },
      { id: 7, title: "Assign Tasks" },
      { id: 8, title: "data Filters, sort & export" },
    ]
  }
];

export const pricingAltData = [
  {
    id: 0,
    title: "Free",
    price: 0,
    period: "m",
    icon: freeIcon,
    url: "/user/subscription-payment",
    buttonText: "Subscribe",
    isFeatured: false,
    features: [
      { id: 1, title: "Basic Player Profile", isActive: true },
      { id: 2, title: "Connect to 1 device only", isActive: true },
      { id: 3, title: "Live Bowling Data", isActive: true },
      { id: 4, title: "Task dependencies", isActive: true },
      { id: 5, title: "Firmware updates", isActive: true },
    ],
  },
  {
    id: 1,
    title: "Pro",
    price: 99,
    period: "m",
    icon: proIcon,
    url: "/user/subscription-payment",
    buttonText: "Subscribe",
    isFeatured: true,
    features: [
      { id: 1, title: "Bowling History", isActive: true  },
      { id: 2, title: "Video Mode" , isActive: true },
      { id: 3, title: "Graphs and trends", isActive: true  },
      { id: 4, title: "training Tasks", isActive: true },
      { id: 5, title: "Training videos", isActive: true  },
      { id: 5, title: "Instant Feedback" , isActive: true },
      { id: 5, title: "Session Stats" , isActive: true },
      { id: 5, title: "Gamiflication", isActive: true  },
      { id: 5, title: "Leader Board", isActive: true  },
      { id: 5, title: "Share Stats on Social Media" , isActive: true },
    ],
  },
  {
    id: 2,
    title: "Pro",
    price: 99,
    period: "m",
    icon: proIcon,
    url: "/user/subscription-payment",
    buttonText: "Subscribe",
    isFeatured: true,
    features: [
      { id: 1, title: "Multiple Player History", isActive: true  },
      {
        id: 2,
        title: "Player Comparison",
         isActive: true ,
      },
      { id: 3, title: "Connect Multiple Devices", isActive: true  },
      { id: 4, title: "Advance Analytics", isActive: true  },
      { id: 5, title: "Interface With Player/Coach", isActive: true  },
      { id: 6, title: "Metrics correlation", isActive: true  },
      { id: 7, title: "Assign Tasks", isActive: true  },
      { id: 8, title: "data Filters, sort & export", isActive: true  },
    ],
  },
];
