import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";
import FalconCardHeader from "components/common/FalconCardHeader";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Attibutes = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [isBusy, setIsBusy] = useState(false);
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  //access control
  useEffect(() => {
    if (user_info.access == "player" || user_info.access == "coach") {
      if (user_info.access == "player") navigate("/playerdashboard");
      else if (user_info.access == "coach") navigate("/coachdashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsBusy(true);
      const response = await axios.get(`api/getProfile/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setIsBusy(false);
      setUserData(response.data.user);
      setFormData({
        name: response.data.user.name,
        email: response.data.user.email
      });
      
    })();
  }, []);

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBusy(true);
    const post = {
      user_id: id,
      name: formData.name,
      email: formData.email,
      password: formData.password,
    };
    let res = null;
    try {
      res = await axios.post("api/updateUserDataByAdmin", post, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      if (res) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
      setIsBusy(false);
    } catch (e) {
      setIsBusy(false);
      toast.error(`${e.response.data.error}`, {
        theme: "colored",
      });
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <FalconCardHeader
              title="Update User Attributes"
              className="mb-3 p-2 "
            />
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      placeholder="Name"
                      value={formData.name}
                      name="name"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      placeholder="Email address"
                      value={formData.email}
                      name="email"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>

                  <Row className="g-2 mb-3">
                    <Form.Group as={Col} sm={6}>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        placeholder="Password"
                        value={formData.password}
                        name="password"
                        onChange={handleFieldChange}
                        type="password"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={6}>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        name="confirmPassword"
                        onChange={handleFieldChange}
                        type="password"
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-4">
                    <Button className="w-100" type="submit">
                      Update
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Attibutes;
