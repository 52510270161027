import React, {useEffect} from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Subscription() {
  const navigate = useNavigate();
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const stripePromise = loadStripe("pk_test_51LoOKXLPp6wETtzzeGP1ASPfCnqQpW7fR00u48Uz7NvlFCsSVvhDap392KKRLE7OA9oGGEbRe5ueEQYcvRKFdmEP00b1RUh703");
    console.log(stripePromise);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: "fetchClientSecretFromServer()",
  };

  //access control
  useEffect(() => {
    if (user_info.access == "admin") {
      navigate("/admindashboard");
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);
  return (
    <>
      <h3 className="mb-4">Payment Checkout Form</h3>
      <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements>
    </>
  );
}

export default Subscription;
