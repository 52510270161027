// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAyITvDmlXuNhmfK1O1kxpW07yOJAmCxjo",
  authDomain: "cricflex-b29d6.firebaseapp.com",
  projectId: "cricflex-b29d6",
  storageBucket: "cricflex-b29d6.appspot.com",
  messagingSenderId: "146088915305",
  appId: "1:146088915305:web:7648dd11a69011c3f54df8",
  measurementId: "G-WQ0MXPLTXB"
};


const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () =>{
const permission = await Notification.requestPermission();
var token = null;
console.log(permission);
if(permission === "granted"){
    token = await getToken(messaging, {
        vapidKey:"BDNhXDcEm_sF8T1y8Lsc2WJF6iSLALoc9P3QcuDkOq1DMGwqc9xQjyctJzijGSMluQZH1nJCkgQBholJEsVF1HQ"
    });
}
return token;
}