import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import TotalBalls from "./TotalBalls";
import { Row, Col } from "react-bootstrap";
import axios from "api/axios";
import {
  marketShare,
  totalOrder,
  weeklySalesData,
} from "data/dashboard/default";
import LegalBalls from "./LegalBalls";
import MarketShare from "./MarketShare";
import Statistics from "./Statistics";
import { ThreeDots } from "react-loader-spinner";

const Dashboard = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [statsLoading, setStatsLoading] = useState(true);
  const [lastMonthData, setLastMonthData] = useState([]);

  useEffect(() => {
    (async () => {
      setStatsLoading(true);
      const player = {
        user_id: user_info.id,
      };
      try {
        const res = await axios.post("api/coachDashboard", player, {
          headers: { Authorization: `Bearer ${access_token}` },
        });

        setLastMonthData(res.data);
        setStatsLoading(false);
        console.log(lastMonthData);
      } catch (e) {
        if (e.response) {
          // console.log(e.response);
        }
      }
    })();
  }, []);
  return (
    <>
      {user_info && (
        <>
          {user_info.access === "coach" ? (
            <>
              <Row className="g-3 mb-3">
                <p>Welcome to Coach Dashboard</p>
                {!statsLoading ? (
                  <>
                    <Col md={6} xxl={3}>
                      <LegalBalls
                        title={"Total # of Players"}
                        data={totalOrder}
                        stats={lastMonthData.totalPlayers}
                        loading={statsLoading}
                      />
                    </Col>
                    <Col md={6} xxl={3}>
                      <LegalBalls
                        title={"Total # of Balls Bowled"}
                        data={totalOrder}
                        stats={lastMonthData.totalBowls}
                        loading={statsLoading}
                      />
                    </Col>
                    <Col md={6} xxl={3}>
                      <LegalBalls
                        title={"Trainging Hours"}
                        data={totalOrder}
                        stats={lastMonthData.workingHours}
                        loading={statsLoading}
                      />
                    </Col>
                    <Col md={6} xxl={3}>
                      <LegalBalls
                        title={"Videos Watched"}
                        data={totalOrder}
                        stats={lastMonthData.videos}
                        loading={statsLoading}
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#F2F3F5"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ margin: "0 350px" }}
                      wrapperClassName="justif-content-center"
                      visible={true}
                    />
                  </>
                )}
              </Row>
            </>
          ) : (
            <>
              {" "}
              <Navigate to="/" />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
