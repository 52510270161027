import React, { useState, useEffect, useRef } from "react";
import axios from "api/axios";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import GroupModal from "components/pages/groups/GroupModal";
import AssignModal from "components/pages/groups/AssignModal";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cross } from "d3";
import { faCross, faTrash } from "@fortawesome/free-solid-svg-icons";

const Groups = () => {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));
  const [updated, setUpdated] = useState(0);
  const [current, setCurrent] = useState(null);
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  //access control
  useEffect(() => {
    if (user_info.access == "admin" || user_info.access == "player") {
      if (user_info.access == "admin") {
        navigate("/Admindashboard");
      } else {
        navigate("/playerdashboard");
      }
      toast.error(`ERROR! ${"You are not allowed to access this resource."}`, {
        theme: "colored",
        toastId: "error1",
      });
    }
  }, []);

  // data table filtering
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex flex-row-reverse">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search Here"
            style={{ height: "45px" }}
          />
        </span>
      </div>
    );
  };

  // get coach groups
  useEffect(() => {
    (async () => {
      setLoading(true);
      const coach = { coach_id: user_info.id };
      const response = await axios.post(`api/getGroupData`, coach, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setLoading(false);
      setGroups(response.data);
      console.log(response.data);
    })();
  }, [updated]);

  //delete Device
  const deleteDevice = async () => {
    const Device = {
      device_id: current.device.id,
    };

    try {
      const res = await axios.post("api/deleteDeviceFromAdminSide", Device, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      console.log(res);
      if (res) {
        setUpdated(Math.random());
      }
      if (res.status === 201) {
        toast.success(`SUCCESS! ${res.data.message}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      if (e.response) {
        toast.error(`${e.response.data.error}`, {
          theme: "colored",
        });
      }
    }
  };

  //delete Player group
  const deletePlayerGroup = async (id) => {
    if (window.confirm("Are you sure you want to delete this group?")) {
      try {
        const res = await axios.get(`api/deletePlayerGroup/${id}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        console.log(res);
        if (res) {
          setUpdated(Math.random());
        }
        if (res.status === 201) {
          toast.success(`SUCCESS! ${res.data.message}`, {
            theme: "colored",
          });
        }
      } catch (e) {
        if (e.response) {
          toast.error(`${e.response.data.error}`, {
            theme: "colored",
          });
        }
      }
    }
  };
  const buttonBodyTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2 ">
        <AssignModal updated={setUpdated} id={data.group.id} />
        <Button
          variant="link"
          size="sm"
          className="mx-2"
          onClick={(e) => {
            e.preventDefault();
            deletePlayerGroup(data.group.id);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  //Remove player from player group
  const removePlayerFromGroup = async (player_id, group_id) => {
    if (window.confirm("Are you sure you want to delete this video?")) {
      try {
        const res = await axios.get(
          `api/removePlayerFromGroup/${group_id}/${player_id}`,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );
        console.log(res);
        if (res) {
          setUpdated(Math.random());
        }
        if (res.status === 201) {
          toast.success(`SUCCESS! ${res.data.message}`, {
            theme: "colored",
          });
        }
      } catch (e) {
        if (e.response) {
          toast.error(`${e.response.data.error}`, {
            theme: "colored",
          });
        }
      }
    }
  };

  const playersBodyTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2 ">
        {data.players.length > 0 ? (
          <>
            {data.players.map((player) => (
              <span className="mx-1 bg-light p-2 rounded">
                {player.name}
                <Button
                  variant="link"
                  size="sm"
                  className="text-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    removePlayerFromGroup(player.id, data.group.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </span>
            ))}
          </>
        ) : (
          <>
            <p>No players </p>
          </>
        )}
      </div>
    );
  };
  const header = renderHeader();
  return (
    <>
      {user_info.access === "coach" && <GroupModal updated={setUpdated} />}
      <DataTable
        paginator
        rows={5}
        showGridlines
        tableStyle={{ minWidth: "50rem" }}
        value={groups}
        loading={loading}
        filters={filters}
        globalFilterFields={["device.group_name"]}
        header={header}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column header="Group Name" field="group.group_name"></Column>
        <Column
          header="Assigned Players"
          field="firmware.name"
          body={playersBodyTemplate}
        ></Column>
        <Column header="Action" body={buttonBodyTemplate}></Column>
      </DataTable>
    </>
  );
};

export default Groups;
